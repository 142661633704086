
/* eslint-disable */
import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import axios from 'axios'
import authHeader from '../services/auth-header'
import DatesSelector from '../components/DatesSelector.vue'
import DialogWait from '../components/DialogWait.vue'
import DialogYesNo from '../components/DialogYesNo.vue'

@Component({
  components: {
    DatesSelector: DatesSelector,
    DialogWait: DialogWait,
    DialogYesNo: DialogYesNo
  }
})
export default class Bets extends Vue {
  public getColor(item: any) {
    if (item.amount >= 0) {
      return 'green'
    } else {
        return 'red'
    }
  }

  public exportExcel () {
    console.log('Excel')
  }

  public trading_view_symbol = ''
  public predictionSearch = ''
  public versions = []
  public versionsList = this.$store.state.versions
  public searchExpansion = [0]

  public statusSelected = 0
  public status = [
    {
      id: 0,
      name: 'All'
    },
    {
      id: 1,
      name: 'Only Hits'
    },
    {
      id: 2,
      name: 'Only Errors'
    }
  ]
  public betsLoading = false
  public platforms = this.$store.state.platforms
  public platformsSelected = []
  public showClosed = false
  public bet_status = 1
  public bet_status_list = [
    {
      id: 0,
      name: 'All'
    },
    {
      id: 1,
      name: 'Open'
    },
    {
      id: 2,
      name: 'Closed'
    }
  ]
  public showBinary = true

  public dataPointIndex = 0

  public betsHeaders = [
    { text: 'Actions', value: 'actions', sortable: false },
    { text: 'Name', value: 'xtb_symbol' },
    { text: 'Version', value: 'name_groping' },
    { text: 'Timeshift', value: 'timeshift_minutes' },
    { text: 'Status', value: 'status' },
    { text: 'Bet', value: 'prediction' },
    { text: 'Amount', value: 'amount' },
    { text: 'Buy Price', value: 'buy_price' },
    { text: 'Open Date', value: 'moment' },
    { text: 'Open Value', value: 'open_value' },
    { text: 'Close Value', value: 'close_value' },
    { text: 'Close Date', value: 'moment_close' },
    { text: 'Close Motive', value: 'request_close_motive' },
    { text: 'Bet Duration', value: 'close_bet_duration' },
    { text: 'Current', value: 'current_value' },
    { text: 'Money Exposed', value: 'max_amount_exposed' },
    { text: 'Max Win', value: 'min_amount_exposed' },
    { text: 'Total Highs', value: 'number_amount_high' },
    { text: 'Total Lows', value: 'number_amount_low' },
    { text: 'Higher High', value: 'amount_high' },
    { text: 'Lower Low', value: 'amount_low' },
    { text: 'Last High (Min.)', value: 'last_high_minutes' },
    { text: 'Last Low (Min.)', value: 'last_low_minutes' },
    { text: 'Volume', value: 'volume' },
    { text: 'Spread Warning', value: 'warning_spread_price' },
  ]

  public betsRows = []

  private async mounted() {
    await this.$store.dispatch('initialState', this)
    this.versionsList = this.$store.state.versions
    this.platforms = this.$store.state.platforms
    clearInterval(this.$store.state.betTimer1)

    this.retreiveData()

    this.$store.state.betTimer1 = setInterval(async () => {
      this.retreiveData()
    }, 30000)
  }

  public refresh() {
    clearInterval(this.$store.state.betTimer1)

    this.retreiveData()

    this.$store.state.betTimer1 = setInterval(async () => {
      this.retreiveData()
    }, 30000)
  }

  public async retreiveData() {
    this.betsLoading = true
    const { dateBegin, dateEnd } = this.$refs.selectDates as any
    try {
      const res = await axios.get(
        `${this.$store.state.URL_API}/finance/v2/bets`,
        {
          params: {
            versions: this.versions.length > 0 ? this.versions : [-1],
            platforms: this.platformsSelected.length > 0 ? this.platformsSelected : [-1],
            showClosed: this.bet_status,
            showBinary: this.showBinary,
            statusSelected: this.statusSelected,
            dateBegin,
            dateEnd
          },
          headers: authHeader()
        }
      )

      this.betsRows = res.data.rows
    } catch (e) {
      console.error(e)
    }

    this.betsLoading = false
  }

  public async showChart(item: any) {
    window.open(`/chart?symbol=${item.tradingview_symbol}`)
  }

  public $refs!: {
    selectDates: any
    dialogWait: any
    dialogYesNo: any
  }

  $store: any

}
