<template>
  <v-dialog v-model="dialog" hide-overlay persistent width="300">
    <v-card color="primary" dark >
      <v-card-text>
        {{ message }}
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    message: null
  }),
  methods: {
    open (message = 'Please, wait...') {
      this.dialog = true
      this.message = message
    },
    close () {
      this.dialog = false
    }
  }
}
</script>
