import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Symbols from '../views/Symbols.vue'
import Bets from '../views/Bets.vue'
import Statistics from '../views/Statistics.vue'
import Brokers from '../views/Brokers.vue'
import Platforms from '../views/Platforms.vue'
import Versions from '../views/Versions.vue'
import Lynch from '../views/Lynch.vue'
import LynchHistorical from '../views/LynchHistorical.vue'
import BackTester from '../views/BackTester.vue'
import Drivers from '../views/Drivers.vue'
// import Future from '../views/Future.vue'
import Spreads from '../views/Spreads.vue'
import BetsBySpread from '../views/BetsBySpread.vue'
import Users from '../views/Users.vue'
import Notifications from '../views/Notifications.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/bets',
    name: 'Bets',
    component: Bets
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics
  },
  {
    path: '/symbols',
    name: 'Symbols',
    component: Symbols
  },
  {
    path: '/lynch',
    name: 'Lynch',
    component: Lynch
  },
  {
    path: '/lynch/:id',
    name: 'Lynch-id',
    component: Lynch
  },
  {
    path: '/bets_by_spread',
    name: 'BetsBySpread',
    component: BetsBySpread
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications
  },
  {
    path: '/chart',
    name: 'Chart',
    component: () => import(/* webpackChunkName: "evolution" */ '../views/Chart.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "evolution" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
