import axios from 'axios'

// const URL_API_PROD = 'http://localhost:3000'
// const URL_API_PROD = 'http://193.36.145.49:3081'
// const URL_API_PROD = 'http://75.119.131.13:3000'
const URL_API_PROD = 'https://superlynch.com'

const URL_API = `${URL_API_PROD}/finance/auth/`

class AuthService {
  login (user: any) {
    return axios
      .post(URL_API + 'signin', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }

  logout () {
    localStorage.removeItem('user')
  }

  register (user: any) {
    return axios.post(URL_API + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    })
  }
}

export default new AuthService()
