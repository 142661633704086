export default function authHeader () {
  let user = {
    accessToken: ''
  }
  if (localStorage.getItem('user')) {
    user = JSON.parse(localStorage.getItem('user') || '')
  }

  if (user && user.accessToken) {
    return { 'x-access-token': user.accessToken }
  } else {
    return {}
  }
}
