<template>
  <div id="app">
    <v-app-bar dark dense v-if="hasToken">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div class="drove-text-color">{{appVersion}}</div>
      <v-btn icon>
        <v-icon @click.prevent="toggleFullScreen">mdi-fullscreen</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon @click.prevent="handleNewDashboard">mdi-open-in-new</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon @click.prevent="logOut">mdi-login-variant</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- <div style="line-height: 22px; background-color: #272727; color: white; max-width: 100vw">
      {{appVersion}}
    </div> -->
    <div class="offline" v-if="onLine == false"><v-icon color="green">mdi-alert</v-icon>No Internet connectivity</div>
    <v-offline
      online-class="online"
      offline-class="offline"
      @detected-condition="amIOnline">
    </v-offline>
    <v-navigation-drawer dark v-model="drawer" absolute left temporary>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ this.$store.state.auth.user ? this.$store.state.auth.user.username : '' }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ this.$store.state.auth.user ? this.$store.state.auth.user.email : '' }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
          <v-list-item @click="goTo('home')">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="goTo('notifications')">
            <v-list-item-title>Notifications</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="goTo('bets_by_spread')">
            <v-list-item-title>Bets & Spread</v-list-item-title>
          </v-list-item>
          <v-list-item @click="goTo('bets')">
            <v-list-item-title>Bets History</v-list-item-title>
          </v-list-item>
          <v-list-item @click="goTo('statistics')">
            <v-list-item-title>Bets Statistics</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="goTo('lynch')">
            <v-list-item-title>Lynch</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="goTo('symbols')" v-if="showAdminBoard">
            <v-list-item-title>Symbols</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <div>
        <v-tooltip v-if="!$vuetify.theme.dark" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="info" icon @click="darkMode">
              <v-icon>mdi-moon-waxing-crescent</v-icon>
            </v-btn>
          </template>
          <span>Dark Mode On</span>
        </v-tooltip>
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="info" icon @click="darkMode">
              <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
            </v-btn>
          </template>
          <span>Dark Mode Off</span>
        </v-tooltip>
      </div>
        <div class="version">{{appVersion}}</div>
        <div class="pa-2">
          <v-btn block @click.prevent="logOut">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <router-view/>
  </div>
</template>
<script>
import axios from 'axios'
import VOffline from 'v-offline'

require('dotenv').config()
const APP_VERSION = 'v.1.3.0'

export default {
  components: {
    VOffline
  },
  data: () => ({
    drawer: false,
    group: null,
    onLine: null,
    onlineSlot: 'online',
    offlineSlot: 'offline',
    appVersion: APP_VERSION
  }),
  watch: {
    group () {
      this.drawer = false
    }
  },
  computed: {
    hasToken () {
      if (!this.$store.state.auth.user) return false
      return this.$store.state.auth.user.accessToken || false
    },
    currentUser () {
      return this.$store.state.auth.user
    },
    showAdminBoard () {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN')
      }
      return false
    },
    showModeratorBoard () {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR')
      }
      return false
    }
  },
  async mounted () {
    this.$vuetify.theme.dark = localStorage.dark === 'true'
  },
  created () {
    window.onbeforeunload = function () { return false }
    axios.interceptors.response.use((response) => {
      return response
    }, error => {
      // handle the response error
      try {
        if (error.response.status === 401) {
          this.$store.dispatch('auth/logout')
          this.$router.push('/login')
        }
      } catch (e) {
        console.error(e)
      }
      return Promise.reject(error)
    })
  },
  methods: {
    amIOnline (e) {
      this.onLine = e
    },
    darkMode () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.dark = this.$vuetify.theme.dark
    },
    logOut () {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
    goTo (url) {
      clearInterval(this.$store.state.homeTimer1)
      clearInterval(this.$store.state.homeTimer2)
      clearInterval(this.$store.state.betTimer1)
      clearInterval(this.$store.state.betTimer2)
      this.$router.push(`/${url}`)
    },
    handleNewDashboard () {
      window.open(document.location.href, '_blank')
    },
    toggleFullScreen: () => {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
      }
    }
  }
}
</script>
<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-size: 12px;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
  .offline {
    background-color: red;
    color: white;
    line-height: 24px;
    font-size: 12px;
    i {
      color: white;
      font-size: 16px;
      margin-right: 7px;
    }
  }

  .online {
    background-color: #00b712;
    background-image: linear-gradient(315deg, #00b712 0%, #5aff15 74%);
  }
  .apexcharts-canvas {
    color: black !important;
  }

  .v-data-table td {
    font-size: 12px !important;
  }

  .v-input {
    font-size: 12px !important;
  }

  .v-label {
    font-size: 12px !important;
  }

  .v-btn {
    font-size: 12px !important;
  }

  .v-chip {
    font-size: 12px !important;
  }

  .v-toolbar {
    color: white!important;
    font-size: 12px!important;
  }

  .col {
    margin: 2px!important;
    padding: 2px!important;
    font-size: 12px!important;
  }

  .v-divider {
    margin: 10px!important;
  }

  .v-expansion-panel-content__wrap {
    padding: 0 4px 10px!important;
  }

  .v-application {
    background-color: var(--v-background-base) !important;
  }

  .version {
    color:white;
    margin-bottom: 2px;
  }

  .v-expansion-panel-content__wrap {
    padding: 0 4px 10px!important;
  }
  .col {
    margin: 0px!important;
    padding: 0px!important;
    font-size: 12px!important;
  }
  .v-expansion-panel-header {
    padding: 4px 20px;
    min-height: 0px!important;
    color:white;
  }
  td {
    font-size: 12px!important;
    height: 12px!important;
  }
  .drove-title {
    background-color: transparent!important;
    color: #8bc34a !important;
    border-bottom: 1px #8bc34a solid !important;
    font-size: 15px !important;
    font-weight: bold !important;
    box-shadow: none!important;
    min-height: 30px;
  }

  .drove-button {
    color: #8bc34a !important;
  }

  .v-expansion-panel-header__icon i {
    color: #8bc34a !important;
  }

  .v-expansion-panel--active>.v-expansion-panel-header {
    max-height: 50px;
  }

  .v-expansion-panel:before {
    box-shadow: none!important;
  }

  .drove-text-color {
    color: #8bc34a !important;
  }
</style>
