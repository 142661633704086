<template>
  <div class="d-flex ma-0 pa-0">
    <v-col class="ma-0 pa-0">
      <v-menu ref="menuBegin" v-model="menuBegin" :close-on-content-click="false" transition="scale-transition" offset-y>
        <template v-slot:activator="scope">
          <v-text-field
            v-model="dateBeginFormat"
            label="Begin Date"
            readonly
            v-on="scope.on"
            name="dateBegin"
            width="200px"
            size="30"
            dense
            hide-details
          ></v-text-field>
        </template>
        <v-date-picker v-model="dateBegin" no-title scrollable locale="es-es" first-day-of-week="1" show-week locale-first-day-of-year="4">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menuBegin = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.menuBegin.save(dateBegin)">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col class="ma-0 pa-0 ml-2">
      <v-menu ref="menuEnd" v-model="menuEnd" :close-on-content-click="false" transition="scale-transition" offset-y>
        <template v-slot:activator="scope">
          <v-text-field
            v-model="dateEndFormat"
            label="End Date"
            readonly
            v-on="scope.on"
            name="dateEnd"
            width="200px"
            size="30"
            dense
            hide-details
          ></v-text-field>
        </template>
        <v-date-picker v-model="dateEnd" no-title scrollable locale="es-es" first-day-of-week="1" show-week locale-first-day-of-year="4">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menuEnd = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.menuEnd.save(dateEnd)">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  data: () => ({
    menuBegin: false,
    menuEnd: false,
    dateBegin: '',
    dateEnd: moment().format('Y-MM-DD')
  }),
  props: ['begin'],
  mounted: function () {
    this.dateBegin = moment().startOf('isoweek').add(-7 || 0, 'days').format('Y-MM-DD')
  },
  computed: {
    dateBeginFormat: function (vm) {
      const dateFormatted = vm.formatDate(this.dateBegin)
      return dateFormatted
    },
    dateEndFormat: function (vm) {
      const dateFormatted = vm.formatDate(this.dateEnd)
      return dateFormatted
    },
    getDateBegin: function () {
      return `${this.dateBegin} 00:00:00`
    },
    getDateEnd: function () {
      return `${this.dateEnd} 23:59:59`
    }
  },
  methods: {
    formatDate: function (date) {
      if (!date) return null
      const separator = date.indexOf('-') ? '-' : '/'
      const _date$split = date.split(separator)
      const year = _date$split[0]
      const month = _date$split[1]
      const day = _date$split[2]
      return ''.concat(day, '/').concat(month, '/').concat(year)
    }
  }
}
</script>
