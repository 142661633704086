import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module'
import axios from 'axios'
import authHeader from '../services/auth-header'

Vue.use(Vuex)

require('dotenv').config()

// const URL_API_PROD = 'http://localhost:3000'
// const URL_API_PROD = 'http://193.36.145.49:3081'
// const URL_API_PROD = 'http://75.119.131.13:3000'
const URL_API_PROD = 'https://superlynch.com'

export default new Vuex.Store({
  state: {
    homeTimer1: 0,
    homeTimer2: 0,
    betTimer1: 0,
    betTimer2: 0,
    betResultsTimer1: 0,
    binaryStatusTimer: 0,
    URL_API: URL_API_PROD,
    credits: 0,
    versions: [],
    brokers: [],
    platforms: [],
    drivers: [],
    symbols: [],
    status: 'pending'
  },
  mutations: {
    updateStatus (state, status) {
      console.log(state, status)
      Vue.set(state, 'status', status)
    }
  },
  actions: {
    async initialState ({ state, commit }) {
      if (!localStorage.getItem('user')) return
      console.log('initialState', (localStorage.getItem('user')))
      commit('updateStatus', 'pending')

      if (state.versions.length > 0) {
        commit('updateStatus', 'success')
        return Promise.resolve('success')
      }
      const [versions, drivers, symbols, brokers, platforms] = await axios.all([
        axios.get(`${state.URL_API}/finance/versions`, {
          params: {
            active: 1,
            bet: 1
          },
          headers: authHeader()
        }),
        axios.get(`${state.URL_API}/finance/drivers`, { headers: authHeader(), params: { showActive: true } }),
        axios.get(`${state.URL_API}/finance/symbols/list`, { headers: authHeader() }),
        axios.get(`${state.URL_API}/finance/traders2`, { headers: authHeader() }),
        axios.get(`${state.URL_API}/finance/platforms`, { headers: authHeader() })
      ])
      console.log(versions, drivers, symbols, brokers, platforms)
      state.versions = versions.data.rows
      state.drivers = drivers.data.rows
      state.symbols = symbols.data.rows
      state.brokers = brokers.data.rows
      state.platforms = platforms.data.rows
      commit('updateStatus', 'success')
      return Promise.resolve('success')
    },
    async reloadData ({ state }) {
      const [versions, drivers, symbols, brokers, platforms] = await axios.all([
        axios.get(`${state.URL_API}/finance/versions`, {
          params: {
            active: 1,
            bet: 1
          },
          headers: authHeader()
        }),
        axios.get(`${state.URL_API}/finance/drivers`, { headers: authHeader(), params: { showActive: true } }),
        axios.get(`${state.URL_API}/finance/symbols/list`, { headers: authHeader() }),
        axios.get(`${state.URL_API}/finance/traders2`, { headers: authHeader() }),
        axios.get(`${state.URL_API}/finance/platforms`, { headers: authHeader() })
      ])
      console.log(versions, drivers, symbols, brokers, platforms)
      state.versions = versions.data.rows
      state.drivers = drivers.data.rows
      state.symbols = symbols.data.rows
      state.brokers = brokers.data.rows
      state.platforms = platforms.data.rows
    }
  },
  modules: {
    auth
  }
})
