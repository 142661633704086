
/* eslint-disable */
import { Component, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import authHeader from '../services/auth-header'
import DialogMessage from '../components/DialogMessage.vue'
import DialogWait from '../components/DialogWait.vue'
import DialogYesNo from '../components/DialogYesNo.vue'

import ApexChart from 'vue-apexcharts'
export interface TypeVersion {
  tickbytick_enabled: boolean;
  tickbytick_max: number;
  tickbytick_max_sell: number;
  trailing_algorithm: boolean;
  trailing_algorithm_negative: boolean;
  trailing_positive: number;
  trailing_negative: number;
  trailing_negative_return: number;
  trailing_min_ticks: number;
  trailing_min_ticks_down: number;
  intraday_enabled: boolean;
  intraday_model: object;
  intraday_from: number;
  close_bets_9pm: boolean;
  apply_slippage_algo: boolean;
  apply_slippage_algo_pips: number;
  do_not_close_bet_at_9: boolean;
  do_not_bet_between_9_910: boolean;
  bet_on_sunday: boolean;
  bet_on_early_monday: boolean;
  bet_on_friday: boolean;
  intraday_to: number;
  intraday_hours: string;
  id: number;
  name: string;
  active: number;
  bet: number;
  grouping_minutes: number[];
  weekends: number;
  intraday: number;
  early_friday: number;
  driver_close: string;
  driver_close_selected: string[];
  exec_at_minute: string;
  exec_at_hour: string;
  exec_at_selector: number;
  exec_at: string;
  days_rolling_window: number;
  symbols: string;
  symbolsSelected: number[];
  backtester_enabled: number;
  stop_loss_type: string;
  stopLossTypeSelected: number[];
  take_profit_type: string;
  takeProfitTypeSelected: number[];
  close_on_next: number;
  rescue: number;
  bet_not_adviced: number;
  early_monday: number;
  symbols_backtester: string;
  symbols_backtester_alter: string;
  backtester_net_profit: number;
  // last_execution: string;
  backtester_days_rolling_window: number;
  // backtester_net_profit_result: number;
  exchange_id: string;
  exchangeIdSelected: number[];
  platform_id: string;
  platformsSelected: number[];
  fixed_symbols: boolean;
  driver_id: number[];
  drop_last_row: boolean;
  trader_id: number;
  check_spread: number;
  last_direction: number;
  symbols_backtester365: string;
  symbols_backtester60: string;
  symbols_backtester30: string;
  symbols_backtester15: string;
  symbols_backtester7: string;
  // ownerid: number;
  alter: number;
  volume: number;
  take_profit_pips: number;
  stop_loss_pips: number;
  maxTicksAvg: number[];
  maxTicksAvgAmount: number[];
}

@Component({
  components: {
    DialogMessage,
    DialogWait,
    DialogYesNo,
    ApexChart
  }
})
export default class Lynch extends Vue {
  public hoursSelectorList = [
    { id: 0, value: 'None' },
    { id: 1, value: 'Custom Session' },
    { id: 2, value: 'Sydney Session' },
    { id: 3, value: 'Tokyo Session' },
    { id: 4, value: 'London Session' },
    { id: 5, value: 'New York Session' }
  ]
  public hoursSelectorSelected = 1
  public lynchName = ''
  public lynchNameEnabled = false
  public lynchExecutionId = 0
  public showCustom = false
  public menuHourSelector = false
  public menuDateSelector = false
  public menuHourSelectorEnd = false
  public menuDateSelectorEnd = false
  public expansion = [0]
  public showLower10 = false
  public showLower10Value = 10
  public showWinRateUpper70 = false
  public showWinRateUpper70Value = 60
  public showDDLowerThan = false
  public showDDLowerThanValue = 30
  public showRSqUpperThan = false
  public showRSqUpperThanValue = 0.8
  public showPositiveAmount = false
  public showPositiveAmountValue = 0
  public stopRunning = false
  public lynchRunning = false
  public lynchInfo = ''
  public search = ''
  public charts: any[] = []

  public close_bets_9pm = false
  public apply_slippage_algo = false
  public apply_slippage_algo_pips = 5
  public do_not_close_bet_at_9 = false
  public do_not_bet_between_9_910 = false
  public bet_on_sunday = true
  public bet_on_early_monday = true
  public bet_on_friday = true

  public intraday_from = 0
  public intraday_to = 23
  public intraday_hours = ''
  public intraday_enabled = true
  public intraday_model: any = {
    sunday_12am: false,
    sunday_1am: false,
    sunday_2am: false,
    sunday_3am: false,
    sunday_4am: false,
    sunday_5am: false,
    sunday_6am: false,
    sunday_7am: false,
    sunday_8am: false,
    sunday_9am: false,
    sunday_10am: false,
    sunday_11am: false,
    sunday_12pm: false,
    sunday_1pm: false,
    sunday_2pm: false,
    sunday_3pm: false,
    sunday_4pm: false,
    sunday_5pm: false,
    sunday_6pm: false,
    sunday_7pm: false,
    sunday_8pm: false,
    sunday_9pm: false,
    sunday_10pm: false,
    sunday_11pm: true,
    monday_12am: true,
    monday_1am: true,
    monday_2am: true,
    monday_3am: true,
    monday_4am: true,
    monday_5am: true,
    monday_6am: true,
    monday_7am: true,
    monday_8am: true,
    monday_9am: true,
    monday_10am: true,
    monday_11am: true,
    monday_12pm: true,
    monday_1pm: true,
    monday_2pm: true,
    monday_3pm: true,
    monday_4pm: true,
    monday_5pm: true,
    monday_6pm: true,
    monday_7pm: true,
    monday_8pm: true,
    monday_9pm: false,
    monday_10pm: false,
    monday_11pm: true,
    tuesday_12am: true,
    tuesday_1am: true,
    tuesday_2am: true,
    tuesday_3am: true,
    tuesday_4am: true,
    tuesday_5am: true,
    tuesday_6am: true,
    tuesday_7am: true,
    tuesday_8am: true,
    tuesday_9am: true,
    tuesday_10am: true,
    tuesday_11am: true,
    tuesday_12pm: true,
    tuesday_1pm: true,
    tuesday_2pm: true,
    tuesday_3pm: true,
    tuesday_4pm: true,
    tuesday_5pm: true,
    tuesday_6pm: true,
    tuesday_7pm: true,
    tuesday_8pm: true,
    tuesday_9pm: false,
    tuesday_10pm: false,
    tuesday_11pm: true,
    wenesday_12am: true,
    wenesday_1am: true,
    wenesday_2am: true,
    wenesday_3am: true,
    wenesday_4am: true,
    wenesday_5am: true,
    wenesday_6am: true,
    wenesday_7am: true,
    wenesday_8am: true,
    wenesday_9am: true,
    wenesday_10am: true,
    wenesday_11am: true,
    wenesday_12pm: true,
    wenesday_1pm: true,
    wenesday_2pm: true,
    wenesday_3pm: true,
    wenesday_4pm: true,
    wenesday_5pm: true,
    wenesday_6pm: true,
    wenesday_7pm: true,
    wenesday_8pm: true,
    wenesday_9pm: false,
    wenesday_10pm: false,
    wenesday_11pm: true,
    thursday_12am: true,
    thursday_1am: true,
    thursday_2am: true,
    thursday_3am: true,
    thursday_4am: true,
    thursday_5am: true,
    thursday_6am: true,
    thursday_7am: true,
    thursday_8am: true,
    thursday_9am: true,
    thursday_10am: true,
    thursday_11am: true,
    thursday_12pm: true,
    thursday_1pm: true,
    thursday_2pm: true,
    thursday_3pm: true,
    thursday_4pm: true,
    thursday_5pm: true,
    thursday_6pm: true,
    thursday_7pm: true,
    thursday_8pm: true,
    thursday_9pm: false,
    thursday_10pm: false,
    thursday_11pm: true,
    friday_12am: true,
    friday_1am: true,
    friday_2am: true,
    friday_3am: true,
    friday_4am: true,
    friday_5am: true,
    friday_6am: true,
    friday_7am: true,
    friday_8am: true,
    friday_9am: true,
    friday_10am: true,
    friday_11am: true,
    friday_12pm: true,
    friday_1pm: true,
    friday_2pm: true,
    friday_3pm: true,
    friday_4pm: true,
    friday_5pm: true,
    friday_6pm: true,
    friday_7pm: true,
    friday_8pm: true,
    friday_9pm: false,
    friday_10pm: false,
    friday_11pm: false,
    saturday_12am: false,
    saturday_1am: false,
    saturday_2am: false,
    saturday_3am: false,
    saturday_4am: false,
    saturday_5am: false,
    saturday_6am: false,
    saturday_7am: false,
    saturday_8am: false,
    saturday_9am: false,
    saturday_10am: false,
    saturday_11am: false,
    saturday_12pm: false,
    saturday_1pm: false,
    saturday_2pm: false,
    saturday_3pm: false,
    saturday_4pm: false,
    saturday_5pm: false,
    saturday_6pm: false,
    saturday_7pm: false,
    saturday_8pm: false,
    saturday_9pm: false,
    saturday_10pm: false,
    saturday_11pm: false
  }

  public tickbytick_enabled = false
  public tickbytick_max = 0
  public tickbytick_max_sell = 0

  public trailing_algorithm = false
  public trailing_algorithm_negative = false
  public trailing_positive = 0.2
  public trailing_negative = -1
  public trailing_negative_return = -1
  public trailing_min_ticks = 10
  public trailing_min_ticks_down = 10

  public filterMaxSL = 2000
  public filterMinSL = 0

  public filterMaxTP = 2000
  public filterMinTP = 0

  public show_selector = false
  public selected = []

  public total_winrate = 0
  public total_amount = 0
  public total_bets = 0
  public total_max_dropdown = 0

  public backtest = {
    viewDataStyle: 0, // 0 => Summary, 1 => Bets, 2 => Detailed
    viewDataStyleList: [
      { id: 0, value: 'Summary' },
      { id: 1, value: 'Bets' },
      { id: 2, value: 'Detailed' },
    ],
    kwargs: '',
    allColumns: false,
    viewChart: false,
    viewDetail: false,
    stopLowerThan: false,
    showLower10Value: false,
    showPositiveAmountValue: 0,
    showWinRateUpper70Value: 60,
    showDDLowerThanValue: 30,
    showRSqUpperThanValue: 0.8,
    stopLowerThanValue: 0,
    period: 0,
    periodList: [
      { id: 1, value: '1 days' },
      { id: 2, value: '2 days' },
      { id: 3, value: '3 days' },
      { id: 4, value: '4 days' },
      { id: 5, value: '5 days' },
      { id: 6, value: '6 days' },
      { id: 7, value: '7 days' },
      { id: 8, value: '8 days' },
      { id: 9, value: '9 days' },
      { id: 10, value: '10 days' },
      { id: 11, value: '11 days' },
      { id: 12, value: '12 days' },
      { id: 13, value: '13 days' },
      { id: 14, value: '14 days' },
      { id: 15, value: '15 days' },
      { id: 30, value: '1 Month' },
      { id: 90, value: '3 Month' },
      { id: 180, value: '6 Month' },
      { id: 365, value: '1 Year' },
      { id: 730, value: '2 Year' },
      { id: 0, value: 'Custom...' }
    ],
    customDate: '2022-01-01',
    customHour: '00:00',
    customDateEnd: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    customHourEnd: '23:59'
  }

    public chartSelectedSymbol = 0

  public options = {
    chart: {
      id: 'backtesting_chart',
      animations: {
        enabled: false
      },
      legend: {
        show: false,
        position: 'top'
      }
    },
    markers: {
      size: 0
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    xaxis: {
      type: 'datetime',
      categories: [],
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        offsetX: 14,
        offsetY: -5
      },
      tooltip: {
        enabled: true
      }
    },
    tooltip: {
      x: {
        format: 'yyyy-MM-dd HH:mm'
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    }
  }

  public series: any[] = []

  
  public tab = null

  public showBetBot = true
  public searchExpansion = [0]

  public resultList: any[] = []
  public resultListFiltered: any[] = []
  public resultSelected: any[] = []
  public resultHeaders: any[] = []
  public resultLoading = false

  public bets_headers: any[] = []
  public bets_list: any[] = []
  public bets_list_filtered: any[] = []
  public bets_search: string = ''

  public detailed_headers: any[] = []
  public detailed_list: any[] = []
  public detailed_list_filtered: any[] = []
  public detailed_search: string = ''

  public ourRequest = axios.CancelToken.source()
  $store: any
  $route: any
  $router: any
  vApp: any
  VApp: any
  vMain: any
  VMain: any
  vContainer: any
  VContainer: any
  vCard: any
  VCard: any
  vExpansionPanels: any
  VExpansionPanels: any
  vExpansionPanel: any
  VExpansionPanel: any
  vExpansionPanelHeader: any
  VExpansionPanelHeader: any
  vExpansionPanelContent: any
  VExpansionPanelContent: any
  vRow: any
  VRow: any
  vCheckbox: any
  VCheckbox: any
  vTextField: any
  VTextField: any
  vDivider: any
  VDivider: any
  vCol: any
  VCol: any
  vSelect: any
  VSelect: any
  vMenu: any
  VMenu: any
  vDatePicker: any
  VDatePicker: any
  vTimePicker: any
  VTimePicker: any
  vBtn: any
  VBtn: any
  vIcon: any
  VIcon: any
  vSpacer: any
  VSpacer: any
  vSwitch: any
  VSwitch: any
  vToolbar: any
  VToolbar: any
  vToolbarTitle: any
  VToolbarTitle: any
  vTabs: any
  VTabs: any
  vTabsSlider: any
  VTabsSlider: any
  vTab: any
  VTab: any
  vTabItem: any
  VTabItem: any
  vDataTable: any
  VDataTable: any
  vChip: any
  VChip: any
  vImg: any
  VImg: any
  dialogMessage: any
  DialogMessage: any
  dialogWait: any
  DialogWait: any
  dialogYesNo: any
  DialogYesNo: any

  public getWinRateColor (item: any) {
    if (item.bet_win_rate >= this.showWinRateUpper70Value) {
      return 'green'
    } else {
      return 'red'
    }
  }

  public getBetAmountColor (item: any) {
    if (item.bet_amount > this.showPositiveAmountValue) {
      return 'green'
    } else if (item.bet_amount < this.showPositiveAmountValue) {
      return 'red'
    }
  }

  public getCumSum (item: any) {
    if (item.cumSum > this.showPositiveAmountValue) {
      return 'green'
    } else {
      return 'red'
    }
  }

  public getAmountSum (item: any) {
    if (item.bet_amount_sum > this.showPositiveAmountValue) {
      return 'green'
    } else {
      return 'red'
    }
  }

  public getAmountSum2 (item: any) {
    if (item.amountSum2 > this.showPositiveAmountValue) {
      return 'green'
    } else {
      return 'red'
    }
  }

  public coloredNumber (value: any, minValue: number) {
    if (value > minValue) {
      return 'green'
    } else {
      return 'red'
    }
  }

  public output = ''
  public versionsLoading = false

  public driverList: any[] = []
  public driversSelected: any[] = []

  public takeProfitTypeList = [
    { text: 'None', value: '0' },
    { text: 'For 1H Bets between minute 10 and 65', value: '1' },
    { text: 'For 1H Bets after 65 minutes', value: '2' },
    { text: 'For 1m Bets', value: '3' }
  ]

  public stopLossTypeList = [
    { text: 'None', value: '0' },
    { text: 'For 1H Bets between minute 10 and 65', value: '1' },
    { text: 'For 1H Bets after 65 minutes', value: '2' },
    { text: 'For 1m Bets', value: '3' }
  ]

  public versionSelected = []
  public versionSelectedEdit: TypeVersion = {} as any

  public exchangeIdList = [
    { text: 'All', value: 0 },
    { text: 'Forex', value: 1 },
    { text: 'Raw Materials', value: 2 },
    { text: 'Indexes', value: 3 },
    { text: 'US Stocks', value: 4 },
    { text: 'Other Stocks ', value: 5 },
    { text: 'Cryptos', value: 8 }
  ]

  public versionsSearch = ''
  public versionsHeaders = [
    { text: 'id', value: 'id' },
    { text: 'Name', value: 'name_groping' },
    { text: 'Bet', value: 'bet' },
    { text: 'Actions', value: 'actions' }
  ]

  public executionList = [
    { text: 'Minute', value: 0 },
    { text: 'Hour', value: 1 }
  ]

  public closeOnNextSelected = 1
  public closeOnNextList = [
    { text: 'Never', value: 0 },
    { text: 'Always', value: 1 },
    { text: 'Only if amount < 0', value: 2 },
    { text: 'Only if amount > 0', value: 3 }
  ]

  public brokerSelected = 1
  public brokerList = [
    { value: 0, text: 'XTB' },
    { value: 1, text: 'Darwinex' },
    { value: 2, text: 'Tickmill' },
    { value: 3, text: 'Oanda' },
    { value: 4, text: 'Pepperstone' }
  ]

  public checkSpreadSelected = 70
  public checkSpreadList = [
    { text: 'Spread Value = 0', value: 0 },
    { text: 'Minimum Spread Value', value: 1 },
    { text: 'Maximum Spread Value', value: 2 },
    { text: 'Average Spread', value: 100 },
    { text: '10% of average spread', value: 10 },
    { text: '20% of average spread', value: 20 },
    { text: '30% of average spread', value: 30 },
    { text: '40% of average spread', value: 40 },
    { text: '50% of average spread', value: 50 },
    { text: '60% of average spread', value: 60 },
    { text: '70% of average spread', value: 70 },
    { text: '80% of average spread', value: 80 },
    { text: '90% of average spread', value: 90 },
    { text: '100% of average spread', value: 100 },
    { text: '110% of average spread', value: 110 },
    { text: '120% of average spread', value: 120 },
    { text: '130% of average spread', value: 130 },
    { text: '140% of average spread', value: 140 },
    { text: '150% of average spread', value: 150 },
    { text: '160% of average spread', value: 160 },
    { text: '170% of average spread', value: 170 },
    { text: '180% of average spread', value: 180 },
    { text: '200% of average spread', value: 200 },
    { text: '250% of average spread', value: 250 },
    { text: '300% of average spread', value: 300 },
    { text: '350% of average spread', value: 350 },
    { text: '400% of average spread', value: 400 },
    { text: '500% of average spread', value: 500 },
    { text: '600% of average spread', value: 600 },
    { text: '700% of average spread', value: 700 },
    { text: '800% of average spread', value: 800 },
    { text: '900% of average spread', value: 900 },
    { text: '1000% of average spread', value: 1000 },
    { text: '1100% of average spread', value: 1100 },
    { text: '120% of average spread', value: 1200 },
    { text: '1300% of average spread', value: 1300 },
    { text: '1400% of average spread', value: 1400 },
    { text: '1500% of average spread', value: 1500 },
    { text: '1600% of average spread', value: 1600 },
    { text: '1700% of average spread', value: 1700 },
    { text: '1800% of average spread', value: 1800 },
    { text: '1900% of average spread', value: 1900 },
    { text: '2000% of average spread', value: 2000 }
  ]

  public driverCloseSelected = ['0']
  public driverCloseList = [
    { text: 'None', value: '0' },
    { text: 'Close with signalStop', value: '1' },
    { text: 'Close Driver 2', value: '2' },
    { text: 'Close Driver 3', value: '3' }
    // { text: 'Close when no signal', value: 4 }
  ]

  public alterSelected = 1
  public alterList = [
    { text: 'All', value: 0 },
    { text: 'Normal', value: 1 },
    { text: 'Opposite', value: 2 }
  ]

  public maxTicksAvg = [0]
  public maxTicksAvgList = [
    { text: 'None', value: 0 },
    { text: 1, value: 1 },
    { text: 2, value: 2 },
    { text: 3, value: 3 },
    { text: 4, value: 4 },
    { text: 5, value: 5 },
    { text: 6, value: 6 },
    { text: 7, value: 7 },
    { text: 8, value: 8 },
    { text: 9, value: 9 },
    { text: 10, value: 10 },
    { text: 15, value: 15 },
    { text: 20, value: 20 },
    { text: 25, value: 25 },
    { text: 30, value: 30 },
    { text: 35, value: 35 },
    { text: 40, value: 40 },
    { text: 45, value: 45 },
    { text: 50, value: 50 },
    { text: 55, value: 55 },
    { text: 60, value: 60 },
    { text: 65, value: 65 },
    { text: 70, value: 70 },
    { text: 75, value: 75 },
    { text: 80, value: 80 },
    { text: 85, value: 85 },
    { text: 90, value: 90 },
    { text: 95, value: 95 },
    { text: 100, value: 100 },
    { text: 105, value: 105 },
    { text: 110, value: 110 },
    { text: 115, value: 115 },
    { text: 120, value: 120 },
    { text: 125, value: 125 },
    { text: 130, value: 130 },
    { text: 135, value: 135 },
    { text: 140, value: 140 },
    { text: 145, value: 145 },
    { text: 150, value: 150 },
    { text: 155, value: 155 },
    { text: 160, value: 160 },
    { text: 165, value: 165 },
    { text: 170, value: 170 },
    { text: 175, value: 175 },
    { text: 180, value: 180 },
    { text: 185, value: 185 },
    { text: 190, value: 190 },
    { text: 195, value: 195 },
    { text: 200, value: 200 },
    { text: 205, value: 205 },
    { text: 210, value: 210 },
    { text: 215, value: 215 },
    { text: 220, value: 220 },
    { text: 225, value: 225 },
    { text: 230, value: 230 },
    { text: 235, value: 235 },
    { text: 240, value: 240 },
    { text: 245, value: 245 },
    { text: 250, value: 250 },
    { text: 255, value: 255 },
    { text: 260, value: 260 },
    { text: 265, value: 265 },
    { text: 270, value: 270 },
    { text: 275, value: 275 },
    { text: 280, value: 280 },
    { text: 285, value: 285 },
    { text: 290, value: 290 },
    { text: 295, value: 295 },
    { text: 300, value: 300 },
    { text: 320, value: 320 },
    { text: 340, value: 340 },
    { text: 360, value: 360 },
    { text: 380, value: 380 },
    { text: 400, value: 400 },
    { text: 420, value: 420 },
    { text: 440, value: 440 },
    { text: 460, value: 460 },
    { text: 480, value: 480 },
    { text: 500, value: 500 },
    { text: 550, value: 550 },
    { text: 600, value: 600 },
    { text: 650, value: 650 },
    { text: 700, value: 700 },
    { text: 750, value: 750 },
    { text: 800, value: 800 },
    { text: 850, value: 850 },
    { text: 900, value: 900 },
    { text: 950, value: 950 }
  ]

  public maxTicksAvgAmount = [0]
  public maxTicksAvgAmountList = [
    { text: 'None', value: 0 },
    { text: '20 €', value: 20 },
    { text: '15 €', value: 15 },
    { text: '10 €', value: 10 },
    { text: '9 €', value: 9 },
    { text: '8 €', value: 8 },
    { text: '7 €', value: 7 },
    { text: '6 €', value: 6 },
    { text: '5 €', value: 5 },
    { text: '4 €', value: 4 },
    { text: '3 €', value: 3 },
    { text: '2 €', value: 2 },
    { text: '1 €', value: 1 },
    { text: '0.5 €', value: 0.5 },
    { text: '0.1 €', value: 0.1 },
    { text: '0 €', value: 0 },
    { text: '-1€', value: -1 },
    { text: '-2€', value: -2 },
    { text: '-3€', value: -3 },
    { text: '-4€', value: -4 },
    { text: '-5€', value: -5 },
    { text: '-6€', value: -6 },
    { text: '-7€', value: -7 },
    { text: '-8€', value: -8 },
    { text: '-9€', value: -9 },
    { text: '-10€', value: -10 },
    { text: '-15€', value: -15 },
    { text: '-20€', value: -20 }
  ]

  public stopLossSelected = [70]
  public stopLossList = [
    { text: 'None', value: 0 },
    { text: 1, value: 1 },
    { text: 2, value: 2 },
    { text: 3, value: 3 },
    { text: 4, value: 4 },
    { text: 5, value: 5 },
    { text: 6, value: 6 },
    { text: 7, value: 7 },
    { text: 8, value: 8 },
    { text: 9, value: 9 },
    { text: 10, value: 10 },
    { text: 15, value: 15 },
    { text: 20, value: 20 },
    { text: 25, value: 25 },
    { text: 30, value: 30 },
    { text: 35, value: 35 },
    { text: 40, value: 40 },
    { text: 45, value: 45 },
    { text: 50, value: 50 },
    { text: 55, value: 55 },
    { text: 60, value: 60 },
    { text: 65, value: 65 },
    { text: 70, value: 70 },
    { text: 75, value: 75 },
    { text: 80, value: 80 },
    { text: 85, value: 85 },
    { text: 90, value: 90 },
    { text: 95, value: 95 },
    { text: 100, value: 100 },
    { text: 105, value: 105 },
    { text: 110, value: 110 },
    { text: 115, value: 115 },
    { text: 120, value: 120 },
    { text: 125, value: 125 },
    { text: 130, value: 130 },
    { text: 135, value: 135 },
    { text: 140, value: 140 },
    { text: 145, value: 145 },
    { text: 150, value: 150 },
    { text: 155, value: 155 },
    { text: 160, value: 160 },
    { text: 165, value: 165 },
    { text: 170, value: 170 },
    { text: 175, value: 175 },
    { text: 180, value: 180 },
    { text: 185, value: 185 },
    { text: 190, value: 190 },
    { text: 195, value: 195 },
    { text: 200, value: 200 },
    { text: 205, value: 205 },
    { text: 210, value: 210 },
    { text: 215, value: 215 },
    { text: 220, value: 220 },
    { text: 225, value: 225 },
    { text: 230, value: 230 },
    { text: 235, value: 235 },
    { text: 240, value: 240 },
    { text: 245, value: 245 },
    { text: 250, value: 250 },
    { text: 255, value: 255 },
    { text: 260, value: 260 },
    { text: 265, value: 265 },
    { text: 270, value: 270 },
    { text: 275, value: 275 },
    { text: 280, value: 280 },
    { text: 285, value: 285 },
    { text: 290, value: 290 },
    { text: 295, value: 295 },
    { text: 300, value: 300 },
    { text: 320, value: 320 },
    { text: 340, value: 340 },
    { text: 360, value: 360 },
    { text: 380, value: 380 },
    { text: 400, value: 400 },
    { text: 420, value: 420 },
    { text: 440, value: 440 },
    { text: 460, value: 460 },
    { text: 480, value: 480 },
    { text: 500, value: 500 },
    { text: 550, value: 550 },
    { text: 600, value: 600 },
    { text: 650, value: 650 },
    { text: 700, value: 700 },
    { text: 750, value: 750 },
    { text: 800, value: 800 },
    { text: 850, value: 850 },
    { text: 900, value: 900 },
    { text: 950, value: 950 }
  ]

  public takeProfitSelected = [30]
  public takeProfitList = [
    { text: 'None', value: 0 },
    { text: 1, value: 1 },
    { text: 2, value: 2 },
    { text: 3, value: 3 },
    { text: 4, value: 4 },
    { text: 5, value: 5 },
    { text: 6, value: 6 },
    { text: 7, value: 7 },
    { text: 8, value: 8 },
    { text: 9, value: 9 },
    { text: 10, value: 10 },
    { text: 15, value: 15 },
    { text: 20, value: 20 },
    { text: 25, value: 25 },
    { text: 30, value: 30 },
    { text: 35, value: 35 },
    { text: 40, value: 40 },
    { text: 45, value: 45 },
    { text: 50, value: 50 },
    { text: 55, value: 55 },
    { text: 60, value: 60 },
    { text: 65, value: 65 },
    { text: 70, value: 70 },
    { text: 75, value: 75 },
    { text: 80, value: 80 },
    { text: 85, value: 85 },
    { text: 90, value: 90 },
    { text: 95, value: 95 },
    { text: 100, value: 100 },
    { text: 105, value: 105 },
    { text: 110, value: 110 },
    { text: 115, value: 115 },
    { text: 120, value: 120 },
    { text: 125, value: 125 },
    { text: 130, value: 130 },
    { text: 135, value: 135 },
    { text: 140, value: 140 },
    { text: 145, value: 145 },
    { text: 150, value: 150 },
    { text: 155, value: 155 },
    { text: 160, value: 160 },
    { text: 165, value: 165 },
    { text: 170, value: 170 },
    { text: 175, value: 175 },
    { text: 180, value: 180 },
    { text: 185, value: 185 },
    { text: 190, value: 190 },
    { text: 195, value: 195 },
    { text: 200, value: 200 },
    { text: 205, value: 205 },
    { text: 210, value: 210 },
    { text: 215, value: 215 },
    { text: 220, value: 220 },
    { text: 225, value: 225 },
    { text: 230, value: 230 },
    { text: 235, value: 235 },
    { text: 240, value: 240 },
    { text: 245, value: 245 },
    { text: 250, value: 250 },
    { text: 255, value: 255 },
    { text: 260, value: 260 },
    { text: 265, value: 265 },
    { text: 270, value: 270 },
    { text: 275, value: 275 },
    { text: 280, value: 280 },
    { text: 285, value: 285 },
    { text: 290, value: 290 },
    { text: 295, value: 295 },
    { text: 300, value: 300 },
    { text: 320, value: 320 },
    { text: 340, value: 340 },
    { text: 360, value: 360 },
    { text: 380, value: 380 },
    { text: 400, value: 400 },
    { text: 420, value: 420 },
    { text: 440, value: 440 },
    { text: 460, value: 460 },
    { text: 480, value: 480 },
    { text: 500, value: 500 },
    { text: 550, value: 550 },
    { text: 600, value: 600 },
    { text: 650, value: 650 },
    { text: 700, value: 700 },
    { text: 750, value: 750 },
    { text: 800, value: 800 },
    { text: 850, value: 850 },
    { text: 900, value: 900 },
    { text: 950, value: 950 }
  ]

  public groupingsSelected = [30]
  public groupingMinutesList = [
    { text: '1 minutes', value: 1 },
    { text: '5 minutes', value: 5 },
    { text: '10 minutes', value: 10 },
    { text: '15 minutes', value: 15 },
    { text: '20 minutes', value: 20 },
    { text: '25 minutes', value: 25 },
    { text: '30 minutes', value: 30 },
    { text: '35 minutes', value: 35 },
    { text: '40 minutes', value: 40 },
    { text: '45 minutes', value: 45 },
    { text: '50 minutes', value: 50 },
    { text: '55 minutes', value: 55 },
    { text: '60 minutes', value: 60 },
    { text: '120 minutes', value: 120 },
    { text: '240 minutes', value: 240 },
    { text: '360 minutes', value: 360 },
    { text: '720 minutes', value: 720 },
    { text: '721 minutes', value: 721 },
    { text: '722 minutes', value: 722 },
    { text: '1440 minutes', value: 1440 },
    { text: '1441 minutes', value: 1441 },
    { text: '1442 minutes', value: 1442 },
    { text: '2880 minutes', value: 2880 },
    { text: '2881 minutes', value: 2881 },
    { text: '2 minutes', value: 2 },
    { text: '3 minutes', value: 3 },
    { text: '4 minutes', value: 4 },
    { text: '6 minutes', value: 6 },
    { text: '7 minutes', value: 7 },
    { text: '8 minutes', value: 8 },
    { text: '9 minutes', value: 9 },
    { text: '11 minutes', value: 11 },
    { text: '12 minutes', value: 12 },
    { text: '13 minutes', value: 13 },
    { text: '14 minutes', value: 14 },
    { text: '16 minutes', value: 16 },
    { text: '17 minutes', value: 17 },
    { text: '18 minutes', value: 18 },
    { text: '19 minutes', value: 19 },
    { text: '21 minutes', value: 21 },
    { text: '22 minutes', value: 22 },
    { text: '23 minutes', value: 23 },
    { text: '24 minutes', value: 24 },
    { text: '26 minutes', value: 26 },
    { text: '27 minutes', value: 27 },
    { text: '28 minutes', value: 28 },
    { text: '29 minutes', value: 29 }
  ]

  public intraday_options = [
    { text: 'Off', value: 0 },
    { text: 'Bet from 08h to 18h', value: 1 },
    { text: 'Bet from 08h to 18h & Close bets with amount > 0 from 20h', value: 2 },
    { text: 'Bet from 08h to 18h, Close bets with amount > 0 from 20h & Close all bets from 23:30h', value: 3 },
    { text: 'Bet from 00h to 20h', value: 4 },
    { text: 'Bet from 00h to 20h & Close bets with amount > 0 from 20h', value: 5 },
    { text: 'Bet from 00h to 20h, Close bets with amount > 0 from 20h & Close all bets from 23:30h', value: 6 },
    { text: 'Bet Monday, Tuesday, Wenesday, Thursday and Friday', value: 7 },
    { text: 'Bet Tuesday, Wenesday, Thursday and Friday', value: 8 },
    { text: 'Bet Tuesday, Wenesday and Thursday', value: 9 },
    { text: 'Bet Monday, Tuesday, Wenesday and Thursday', value: 10 },
    { text: 'Bet Monday', value: 11 },
    { text: 'Bet Tuesday', value: 12 },
    { text: 'Bet Wenesday', value: 13 },
    { text: 'Bet Thursday', value: 14 },
    { text: 'Bet Friday', value: 15 }
  ]

  public symbolsList: any[] = []
  public symbolsSelected: any[] = [1]
  public traderList = []
  public versionsRows: any[] = []

  public platforms: any[] = []
  public platformsSelected: any[] = []

  public fullScreenStatus = false

  private async mounted () {
    await this.$store.dispatch('initialState', this)
    this.loadPage()
  }

  private async loadPage () {
    this.platforms = this.$store.state.platforms
    this.symbolsList = this.$store.state.symbols
    this.driverList = this.$store.state.drivers
    this.traderList = this.$store.state.brokers

    if (this.$route.query.version) {
      // Get Version parameters
      const versionData = await axios.get(`${this.$store.state.URL_API}/finance/versions/${this.$route.query.version}`, { headers: authHeader() })

      if (versionData.data.rows.length > 0) {
        const data : any = versionData.data.rows[0]
        if (this.$route.query.symbol && this.$route.query.symbol !== '') {
          this.symbolsSelected = [this.$route.query.symbol * 1]
        } else {
          this.symbolsSelected = data.symbols.split(',').map((a: any) => a * 1)
        }
        this.driversSelected = [data.driver_id]
        this.groupingsSelected = [data.grouping_minutes]
        this.closeOnNextSelected = data.close_on_next * 1
        this.checkSpreadSelected = data.check_spread
        this.driverCloseSelected = data.symbols.split(',').map((a: any) => (a * 1).toString())
        this.alterSelected = (data.alter * 1) + 1
        this.stopLossSelected = [data.stop_loss_pips]
        this.takeProfitSelected = [data.take_profit_pips]
        this.maxTicksAvg = [data.max_ticks_avg]
        this.maxTicksAvgAmount = [data.max_ticks_avg_amount]
        this.close_bets_9pm = data.close_at_9pm
        this.apply_slippage_algo = data.apply_slippage_algo
        this.apply_slippage_algo_pips = data.apply_slippage_algo_pips
        this.do_not_bet_between_9_910 = data.do_not_bet_between_9_910
        this.do_not_close_bet_at_9 = data.do_not_close_bet_at_9
        this.intraday_enabled = true
        // this.intraday_model = data.intraday_model
      }
    }

    if (this.$route.query.lynchId) {
      console.log('mounted Lynch 2')
      const lynch_results_rows = []
      this.$refs.dialogWait.open('Loading Lynch, please wait...')
      const { data } = await axios.get(`${this.$store.state.URL_API}/finance/lynch/historical/ids/${this.$route.query.lynchId}`, { headers: authHeader() })

      const parameters = data.lynch.parameters

      const data_results = data.results
      
      for (const result_id of data_results) {
        const lynch_result_row = await axios.get(`${this.$store.state.URL_API}/finance/lynch/historical/result/${result_id.id}`, { headers: authHeader() })
        if (lynch_result_row.data.results.length > 0) {
          lynch_results_rows.push(lynch_result_row.data.results[0].result)
        }
      }

      this.backtest.period = parameters.backtestOptions.period
      this.symbolsSelected = parameters.symbols
      this.driversSelected = parameters.drivers
      this.groupingsSelected = parameters.groups
      this.closeOnNextSelected = parameters.closeBets
      this.brokerSelected = parameters.brokerSelected
      this.checkSpreadSelected = parameters.checkSpreads
      this.stopLossSelected = parameters.stopLosses
      this.takeProfitSelected = parameters.takeProfits
      this.close_bets_9pm = parameters.close_bets_9pm
      this.apply_slippage_algo = parameters.apply_slippage_algo
      this.apply_slippage_algo_pips = parameters.apply_slippage_algo_pips
      this.do_not_bet_between_9_910 = parameters.do_not_bet_between_9_910
      this.do_not_close_bet_at_9 = parameters.do_not_close_bet_at_9
      this.intraday_enabled = true
      this.intraday_model = parameters.intraday_model
      this.backtest.stopLowerThan = parameters.backtestOptions.stopLowerThan
      this.backtest.showLower10Value = parameters.backtestOptions.showLower10Value
      this.backtest.showPositiveAmountValue = parameters.backtestOptions.showPositiveAmountValue
      this.backtest.showWinRateUpper70Value = parameters.backtestOptions.showWinRateUpper70Value
      this.backtest.showDDLowerThanValue = parameters.backtestOptions.showDDLowerThanValue
      this.backtest.showRSqUpperThanValue = parameters.backtestOptions.showRSqUpperThanValue
      this.backtest.viewDataStyle = parameters.backtestOptions.viewDataStyle
      this.backtest.kwargs = parameters.backtestOptions.kwargs
      this.backtest.viewChart = parameters.backtestOptions.viewChart
      this.backtest.viewDetail = parameters.backtestOptions.viewDetail

      if (lynch_results_rows.length > 0) {
        let isFirstTime = true
        for (const row of lynch_results_rows) {
          try {
            const results_data = row
            let summary = []
            let bets = []
            let detailed = []
            let chart = ''
    
            summary = JSON.parse(JSON.parse(results_data).summary)
            bets = JSON.parse(JSON.parse(results_data).bets)
            detailed = JSON.parse(JSON.parse(results_data).detailed)
            chart = JSON.parse(results_data).chart
            
            if (chart != '') this.charts.push(chart)
    
            const summary_keys = Object.keys(summary[0])
            const bets_keys = Object.keys(bets[0])
            const detailed_keys = Object.keys(detailed[0])
    
            if (isFirstTime) {
                isFirstTime = false
                summary_keys.map((key) => {
                  this.resultHeaders.push({ text: key, value: key })
                })
                bets_keys.map((key) => {
                  this.bets_headers.push({ text: key, value: key })
                })
                detailed_keys.map((key) => {
                  this.detailed_headers.push({ text: key, value: key })
                })
            }
    
            if (summary.length > 0) this.resultList = this.resultList.concat(summary)
            if (bets.length > 0) this.bets_list = this.bets_list.concat(bets)
            if (detailed.length > 0) this.detailed_list = this.detailed_list.concat(detailed)
          } catch (e) {
            console.log(e)
          }
        }


        this.filterData()
      }
      // if (data.results && data.results.length > 0 && data.results[0].result && data.results[0].result.length > 0) {
      //   this.resultHeaders = [
      //     { text: 'Date', value: 'Date' },
      //     { text: 'Driver', value: 'driver' },
      //     { text: 'Pair', value: 'pair' },
      //     { text: 'Alter', value: 'alter' },
      //     { text: 'Grouping', value: 'grouping' },
      //     { text: 'SL', value: 'stop_loss_pips' },
      //     { text: 'TP', value: 'take_profit_pips' },
      //     { text: 'Win Rate', value: 'bet_win_rate' },
      //     { text: 'Amount Sum', value: 'bet_amount_sum' },
      //     { text: 'Winners', value: 'winners' },
      //     { text: 'Losers', value: 'losers' },
      //     { text: 'Buys', value: 'buys' },
      //     { text: 'Sells', value: 'sells' },
      //     { text: 'Ticks Avg', value: 'ticks_in_current_bet_avg' },
      //     { text: 'Ticks Max', value: 'ticks_in_current_bet_max' },
      //     { text: 'Drawdown', value: 'DD' },
      //     { text: 'R Square', value: 'R_SQ' }
      //   ]

      //   data.results.map((row: any) => {
      //     this.resultList = this.resultList.concat(row.result)
      //     this.filterData()
      //   })
      // }
      this.$refs.dialogWait.close()
    }

    if (this.$route.query.data) {
      this.loadFromTable(JSON.parse(this.$route.query.data))
    }
  }

  private filterData () {
    if (this.showLower10 && this.showWinRateUpper70 && this.showPositiveAmount) {
      this.resultListFiltered = this.resultList.filter((item: any) => item.winners > this.showLower10Value && item.bet_win_rate >= this.showWinRateUpper70Value && item.bet_amount_sum >= this.showPositiveAmountValue)
      this.bets_list_filtered = this.bets_list
      this.detailed_list_filtered = this.detailed_list
    } else if (!this.showLower10 && this.showWinRateUpper70 && this.showPositiveAmount) {
      this.resultListFiltered = this.resultList.filter((item: any) => item.bet_win_rate >= this.showWinRateUpper70Value && item.bet_amount_sum >= this.showPositiveAmountValue)
      this.bets_list_filtered = this.bets_list
      this.detailed_list_filtered = this.detailed_list
    } else if (this.showLower10 && !this.showWinRateUpper70 && this.showPositiveAmount) {
      this.resultListFiltered = this.resultList.filter((item: any) => item.winners > this.showLower10Value && item.bet_amount_sum >= this.showPositiveAmountValue)
      this.bets_list_filtered = this.bets_list
      this.detailed_list_filtered = this.detailed_list
    } else if (this.showLower10 && this.showWinRateUpper70 && !this.showPositiveAmount) {
      this.resultListFiltered = this.resultList.filter((item: any) => item.winners > this.showLower10Value && item.bet_win_rate >= this.showWinRateUpper70Value)
      this.bets_list_filtered = this.bets_list
      this.detailed_list_filtered = this.detailed_list
    } else if (this.showLower10 && !this.showWinRateUpper70 && !this.showPositiveAmount) {
      this.resultListFiltered = this.resultList.filter((item: any) => item.winners > this.showLower10Value)
      this.bets_list_filtered = this.bets_list
      this.detailed_list_filtered = this.detailed_list
    } else if (!this.showLower10 && this.showWinRateUpper70 && !this.showPositiveAmount) {
      this.resultListFiltered = this.resultList.filter((item: any) => item.bet_win_rate >= this.showWinRateUpper70Value)
      this.bets_list_filtered = this.bets_list
      this.detailed_list_filtered = this.detailed_list
    } else if (!this.showLower10 && !this.showWinRateUpper70 && this.showPositiveAmount) {
      this.resultListFiltered = this.resultList.filter((item: any) => item.bet_amount_sum >= this.showPositiveAmountValue)
      this.bets_list_filtered = this.bets_list.filter((item: any) => item.bet_amount >= this.showPositiveAmountValue)
      this.detailed_list_filtered = this.detailed_list.filter((item: any) => item.bet_amount >= this.showPositiveAmountValue)
    } else {
      this.resultListFiltered = this.resultList
      this.bets_list_filtered = this.bets_list
      this.detailed_list_filtered = this.detailed_list
    }

    if (this.showDDLowerThan) {
      this.resultListFiltered = this.resultListFiltered.filter((item: any) => (item.DD <= this.showDDLowerThanValue))
      this.bets_list_filtered = this.bets_list
      this.detailed_list_filtered = this.detailed_list
    }

    if (this.showRSqUpperThan) {
      this.resultListFiltered = this.resultListFiltered.filter((item: any) => (item.R_SQ >= this.showRSqUpperThanValue))
      this.bets_list_filtered = this.bets_list
      this.detailed_list_filtered = this.detailed_list
    }

    // this.resultListFiltered = this.resultListFiltered.filter((item: any) => (item.stop_loss_pips >= this.filterMinSL && item.stop_loss_pips <= this.filterMaxSL && item.take_profit_pips <= this.filterMaxTP && item.take_profit_pips >= this.filterMinTP))

    this.calculate_totals()
  }

  // @Watch('platformsSelected')
  // async onPlatformsSelected (item: any) {
  //   const rowsSymbols = await axios.get(`${this.$store.state.URL_API}/finance/symbols/platform/${item}`, { headers: authHeader() })
  //   this.symbolsList = rowsSymbols.data.rows
  // }

  @Watch('hoursSelectorSelected')
  async onHoursSelectorSelected (item: number) {
    if (item === 0) {
      this.unSelectAllHours()
    } else if (item === 1) {
      this.selectAllHours()
    } else if (item === 2) {
      this.selectSidney()
    } else if (item === 3) {
      this.selectTokyo()
    } else if (item === 4) {
      this.selectLondon()
    } else if (item === 5) {
      this.selectNewYork()
    }
  }

  @Watch('showLower10')
  onWatchShowLower10 () {
    this.filterData()
  }

  @Watch('showLower10Value')
  onWatchShowLower10Value () {
    this.filterData()
  }

  @Watch('showWinRateUpper70')
  onWatchShowWinRateUpper70 () {
    this.filterData()
  }

  @Watch('showWinRateUpper70Value')
  onWatchShowWinRateUpper70Value () {
    this.filterData()
  }

  @Watch('showPositiveAmount')
  onWatchShowPositiveAmount (item: any) {
    this.filterData()
  }

  @Watch('showPositiveAmountValue')
  onWatchShowPositiveAmountValue (item: any) {
    this.filterData()
  }

  @Watch('showDDLowerThan')
  onWatchshowDDLowerThan () {
    this.filterData()
  }

  @Watch('showDDLowerThanValue')
  onWatchshowDDLowerThanValue () {
    this.filterData()
  }

  @Watch('showRSqUpperThan')
  onWatchshowRSqUpperThan () {
    this.filterData()
  }

  @Watch('showRSqUpperThanValue')
  onWatchshowRSqUpperThanValue () {
    this.filterData()
  }

  @Watch('filterMaxSL')
  onWatchfilterMaxSL (item: any) {
    this.filterData()
  }

  @Watch('filterMinSL')
  onWatchfilterMinSL (item: any) {
    this.filterData()
  }

  @Watch('filterMaxTP')
  onWatchfilterMaxTP (item: any) {
    this.filterData()
  }

  @Watch('filterMinTP')
  onWatchfilterMinTP (item: any) {
    this.filterData()
  }


  @Watch('lynchRunning')
  onLynchRunning (item: any) {
    if (!item) {
      this.$refs.dialogWait.close()
      this.stopRunning = false
    }
  }

  @Watch('backtest.period')
  onBacktestPeriod (item: any) {
    if (item === 0) {
      this.showCustom = true
    } else {
      this.showCustom = false
    }
  }

  private async loadPlatforms () {
    const rows = await axios.get(`${this.$store.state.URL_API}/finance/platforms`, { headers: authHeader() })
    rows.data.rows.unshift({ id: -1, name: 'All' })
    this.platforms = rows.data.rows
  }

  public async stopLynch () {
    this.resultSelected = []
    await axios.post(`${this.$store.state.URL_API}/finance/lynch/cancel`, {
      id: this.lynchExecutionId
    }, {
      headers: authHeader()
    })
    this.lynchExecutionId = 0
    this.ourRequest.cancel()
    this.$refs.dialogWait.open('Stopping Lynch, please wait...')
    this.stopRunning = true
  }

  public addVersion () {
    if (this.resultSelected.length > 0) {
      const routeData = this.$router.resolve({ name: 'Versions', query: { data: JSON.stringify(this.resultSelected[0]) } })
      window.open(routeData.href, '_blank')
    }
  }

  public openFromTable () {
    if (this.resultSelected.length > 0) {
      const routeData = this.$router.resolve({ name: 'Lynch', query: { data: JSON.stringify(this.resultSelected[0]) } })
      window.open(routeData.href, '_blank')
    }
  }

  private loadFromTable (dataItem: any) {
    this.groupingsSelected = [dataItem.grouping]
    this.alterSelected = (dataItem.alter * 1) + 1
    this.stopLossSelected = [dataItem.stop_loss_pips]
    this.takeProfitSelected = [dataItem.take_profit_pips]
    this.maxTicksAvg = [dataItem.maxTicksAvg]
    this.maxTicksAvgAmount = [dataItem.maxTicksAvgAmount]
    const driver = this.driverList.filter((item: any) => item.name === dataItem.driver)
    this.driversSelected = [driver[0].id]
    const symbol = this.symbolsList.filter((item: any) => item.name === dataItem.pair)
    this.symbolsSelected = [symbol[0].id]
  }

  public async runLynch () {
    this.output = ''
    this.charts = []
    this.resultSelected = []
    this.show_selector = false
    this.total_winrate = 0
    this.total_amount = 0
    this.total_bets = 0
    this.total_max_dropdown = 0

    this.chartSelectedSymbol = 0
    
    this.options.xaxis.categories = []
    this.series = []
    this.resultHeaders = []
    this.resultList = []
    this.resultListFiltered = []

    this.resultHeaders = []
    this.bets_headers = []
    this.detailed_headers = []

    this.bets_list = []
    this.detailed_list = []
    this.bets_list_filtered = []
    this.detailed_list = []
    this.detailed_list_filtered = []

    let isFirstTime = true
    let first_symbol = true

    if (this.symbolsSelected.length === 0 || this.driversSelected.length === 0 || this.groupingsSelected.length === 0) {
      this.$refs.dialogMessage.open('Warning', 'Please, select symbols, drivers and gruping', { color: 'red' })
      return
    }
    const estimate = await axios.post(`${this.$store.state.URL_API}/finance/lynch/estimate`, {
      symbols: this.symbolsSelected,
      groups: this.groupingsSelected,
      drivers: this.driversSelected,
      sl: this.stopLossSelected,
      tp: this.takeProfitSelected,
      alter: this.alterSelected,
      backtest: this.backtest
    }, {
      headers: authHeader()
    })

    if (!estimate.data.data) {
      this.$refs.dialogMessage.open('Error', 'Unexpected error, we apologize for it!', { color: 'red' })
      return
    }

    if (estimate.data.data.current < estimate.data.data.estimate) {
      this.$refs.dialogMessage.open('Information', `We're sorry, but you don't have enough credit to do this Superlynch backtest. \r\n\r\nThis Superlynch backtest has a cost of ${estimate.data.data.estimate.toFixed(3)} and you only have ${estimate.data.data.current}.`, { color: 'primary' })
      return
    }

    if (!(await this.$refs.dialogYesNo.open('Question', `The estimate cost of this Superlynch backtest will be around ${estimate.data.data.estimate.toFixed(3)}. Are you sure do you want to continue?`))) return

    this.lynchExecutionId = -1

    if (this.lynchNameEnabled && this.backtest.viewDataStyle == 0) { // 0 => Summary, 1 => Bets, 2 => Detailed
      const lynchCreate = await axios.post(`${this.$store.state.URL_API}/finance/lynch/create`, {
        symbols: this.symbolsSelected,
        drivers: this.driversSelected,
        groups: this.groupingsSelected,
        closeBets: this.closeOnNextSelected,
        checkSpreads: this.checkSpreadSelected,
        brokerSelected: this.brokerSelected,
        driverClose: this.driverCloseSelected,
        stopLosses: this.stopLossSelected,
        takeProfits: this.takeProfitSelected,
        backtest: this.backtest,
        alter: this.alterSelected,
        maxTicksAvg: this.maxTicksAvg,
        maxTicksAvgAmount: this.maxTicksAvgAmount,
        intraday_enabled: true,
        intraday_from: this.intraday_from,
        intraday_to: this.intraday_to,
        intraday_hours: this.intraday_hours,
        intraday_model: this.intraday_model,
        tickbytick_enabled: this.tickbytick_enabled,
        tickbytick_max: this.tickbytick_max,
        tickbytick_max_sell: this.tickbytick_max_sell,
        trailing_algorithm: this.trailing_algorithm,
        trailing_algorithm_negative:  this.trailing_algorithm_negative,
        trailing_positive: this.trailing_positive,
        trailing_negative: this.trailing_negative,
        trailing_negative_return: this.trailing_negative_return,
        trailing_min_ticks: this.trailing_min_ticks,
        trailing_min_ticks_down: this.trailing_min_ticks_down,
        close_bets_9pm: this.close_bets_9pm,
        apply_slippage_algo: this.apply_slippage_algo,
        apply_slippage_algo_pips: this.apply_slippage_algo_pips,
        do_not_close_bet_at_9: this.do_not_close_bet_at_9,
        do_not_bet_between_9_910: this.do_not_bet_between_9_910,
        bet_on_friday: this.bet_on_friday,
        bet_on_sunday: this.bet_on_sunday,
        bet_on_early_monday: this.bet_on_early_monday,
        estimate: estimate.data.data.estimate.toFixed(3),
        lynchName: this.lynchName
      }, { headers: authHeader() })
      // this.lynchExecutionId = lynchCreate.data._lynchId
      this.$refs.dialogMessage.open('Information', 'The backtest is in the queu, you can view the status in Historical.', { color: 'primary' })
      return true
    }

    this.resultLoading = true
    this.lynchRunning = true

    for (const symbol of this.symbolsSelected) {
      for (const driver of this.driversSelected) {
        for (const grouping of this.groupingsSelected) {
          for (const stopLoss of this.stopLossSelected) {
            for (const takeProfit of this.takeProfitSelected) {
              for (const _maxTicksAvg of this.maxTicksAvg) {
                for (const _maxTicksAvgAmount of this.maxTicksAvgAmount) {
                  if ((_maxTicksAvg === 0 && _maxTicksAvgAmount === 0) || (_maxTicksAvg > 0 && _maxTicksAvgAmount !== 0)) {
                    try {
                      if (!this.stopRunning) {
                        this.lynchInfo = `${this.symbolsList.filter(item => item.id === symbol)[0].name} - Driver: ${this.driverList.filter(item => item.id === driver)[0].name} - Group: ${this.groupingMinutesList.filter(item => item.value === grouping)[0].text} - SL: ${stopLoss} - TP: ${takeProfit}`
                        this.ourRequest = axios.CancelToken.source()
                        const data = await axios.post(`${this.$store.state.URL_API}/finance/lynch`, {
                          symbol,
                          driver,
                          grouping,
                          closeBets: this.closeOnNextSelected,
                          checkSpreads: this.checkSpreadSelected,
                          brokerSelected: this.brokerSelected,
                          driverClose: this.driverCloseSelected,
                          stopLoss,
                          takeProfit,
                          backtest: this.backtest,
                          alter: this.alterSelected,
                          maxTicksAvg: _maxTicksAvg,
                          maxTicksAvgAmount: _maxTicksAvgAmount,
                          intraday_enabled: true,
                          intraday_from: this.intraday_from,
                          intraday_to: this.intraday_to,
                          intraday_hours: this.intraday_hours,
                          intraday_model: this.intraday_model,
                          tickbytick_enabled: this.tickbytick_enabled,
                          tickbytick_max: this.tickbytick_max,
                          tickbytick_max_sell: this.tickbytick_max_sell,
                          trailing_algorithm: this.trailing_algorithm,
                          trailing_algorithm_negative: this.trailing_algorithm_negative,
                          trailing_positive: this.trailing_positive,
                          trailing_negative: this.trailing_negative,
                          trailing_negative_return: this.trailing_negative_return,
                          trailing_min_ticks: this.trailing_min_ticks,
                          trailing_min_ticks_down: this.trailing_min_ticks_down,
                          close_bets_9pm: this.close_bets_9pm,
                          apply_slippage_algo: this.apply_slippage_algo,
                          apply_slippage_algo_pips: this.apply_slippage_algo_pips,
                          do_not_close_bet_at_9: this.do_not_close_bet_at_9,
                          do_not_bet_between_9_910: this.do_not_bet_between_9_910,
                          bet_on_friday: this.bet_on_friday,
                          bet_on_sunday: this.bet_on_sunday,
                          bet_on_early_monday: this.bet_on_early_monday,
                          estimate: estimate.data.data.estimate.toFixed(3),
                          lynchId: this.lynchExecutionId
                        }, { headers: authHeader(), cancelToken: this.ourRequest.token })

                        let summary = []
                        let bets = []
                        let detailed = []
                        let chart = ''

                        summary = JSON.parse(JSON.parse(JSON.parse(data.data.data.data)[0]).summary)
                        bets = JSON.parse(JSON.parse(JSON.parse(data.data.data.data)[0]).bets)
                        detailed = JSON.parse(JSON.parse(JSON.parse(data.data.data.data)[0]).detailed)
                        chart = JSON.parse(JSON.parse(data.data.data.data)[0]).chart
                        this.output = JSON.parse(JSON.parse(data.data.data.data)[0]).output

                        if (chart && chart.length > 0) {
                          for (const item of chart) {
                            this.charts.push(item)
                          }
                        }

                        const summary_keys = Object.keys(summary[0])
                        const bets_keys = Object.keys(bets[0])
                        const detailed_keys = Object.keys(detailed[0])

                        if (isFirstTime) {
                          isFirstTime = false
                          summary_keys.map((key) => {
                            this.resultHeaders.push({ text: key, value: key })
                          })
                          bets_keys.map((key) => {
                            this.bets_headers.push({ text: key, value: key })
                          })
                          detailed_keys.map((key) => {
                            this.detailed_headers.push({ text: key, value: key })
                          })
                        }

                        if (summary.length > 0) this.resultList = this.resultList.concat(summary)
                        if (bets.length > 0) this.bets_list = this.bets_list.concat(bets)
                        if (detailed.length > 0) this.detailed_list = this.detailed_list.concat(detailed)

                        this.filterData()
                      }
                    } catch (e) {
                      console.log(e)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    this.lynchInfo = ''

    await axios.post(`${this.$store.state.URL_API}/finance/lynch/status`, {
      status: 2,
      id: this.lynchExecutionId
    }, {
      headers: authHeader()
    })
    this.lynchExecutionId = 0

    this.resultLoading = false
    this.lynchRunning = false
  }

  private calculate_totals () {
    this.total_amount = 0
    this.total_winrate = 0
    this.total_bets = 0
    this.total_max_dropdown = 0
    if (this.backtest.viewDataStyle == 1) {
      for (const row of this.resultListFiltered) {
        this.total_amount = this.total_amount + row.bet_amount
        this.total_bets = this.total_bets + row.winners + row.losers
      }
      this.total_bets = this.resultListFiltered.length
    } else {
      for (const row of this.resultListFiltered) {
        this.total_amount = this.total_amount + row.bet_amount_sum
        this.total_winrate = this.total_winrate + row.bet_win_rate
        this.total_bets = this.total_bets + row.winners + row.losers
        this.total_max_dropdown = this.total_max_dropdown + row.bet_max_dropdown
      }
      if (this.resultListFiltered.length > 0) {
        this.total_winrate = (this.total_winrate * 1) / this.resultListFiltered.length
      }
    }
  }

  public unSelectAllHours () {
    this.intraday_model = {
      sunday_9pm: false,
      sunday_10pm: false,
      sunday_11pm: false,
      monday_12am: false,
      monday_1am: false,
      monday_2am: false,
      monday_3am: false,
      monday_4am: false,
      monday_5am: false,
      monday_6am: false,
      monday_7am: false,
      monday_8am: false,
      monday_9am: false,
      monday_10am: false,
      monday_11am: false,
      monday_12pm: false,
      monday_1pm: false,
      monday_2pm: false,
      monday_3pm: false,
      monday_4pm: false,
      monday_5pm: false,
      monday_6pm: false,
      monday_7pm: false,
      monday_8pm: false,
      monday_9pm: false,
      monday_10pm: false,
      monday_11pm: false,
      tuesday_12am: false,
      tuesday_1am: false,
      tuesday_2am: false,
      tuesday_3am: false,
      tuesday_4am: false,
      tuesday_5am: false,
      tuesday_6am: false,
      tuesday_7am: false,
      tuesday_8am: false,
      tuesday_9am: false,
      tuesday_10am: false,
      tuesday_11am: false,
      tuesday_12pm: false,
      tuesday_1pm: false,
      tuesday_2pm: false,
      tuesday_3pm: false,
      tuesday_4pm: false,
      tuesday_5pm: false,
      tuesday_6pm: false,
      tuesday_7pm: false,
      tuesday_8pm: false,
      tuesday_9pm: false,
      tuesday_10pm: false,
      tuesday_11pm: false,
      wenesday_12am: false,
      wenesday_1am: false,
      wenesday_2am: false,
      wenesday_3am: false,
      wenesday_4am: false,
      wenesday_5am: false,
      wenesday_6am: false,
      wenesday_7am: false,
      wenesday_8am: false,
      wenesday_9am: false,
      wenesday_10am: false,
      wenesday_11am: false,
      wenesday_12pm: false,
      wenesday_1pm: false,
      wenesday_2pm: false,
      wenesday_3pm: false,
      wenesday_4pm: false,
      wenesday_5pm: false,
      wenesday_6pm: false,
      wenesday_7pm: false,
      wenesday_8pm: false,
      wenesday_9pm: false,
      wenesday_10pm: false,
      wenesday_11pm: false,
      thursday_12am: false,
      thursday_1am: false,
      thursday_2am: false,
      thursday_3am: false,
      thursday_4am: false,
      thursday_5am: false,
      thursday_6am: false,
      thursday_7am: false,
      thursday_8am: false,
      thursday_9am: false,
      thursday_10am: false,
      thursday_11am: false,
      thursday_12pm: false,
      thursday_1pm: false,
      thursday_2pm: false,
      thursday_3pm: false,
      thursday_4pm: false,
      thursday_5pm: false,
      thursday_6pm: false,
      thursday_7pm: false,
      thursday_8pm: false,
      thursday_9pm: false,
      thursday_10pm: false,
      thursday_11pm: false,
      friday_12am: false,
      friday_1am: false,
      friday_2am: false,
      friday_3am: false,
      friday_4am: false,
      friday_5am: false,
      friday_6am: false,
      friday_7am: false,
      friday_8am: false,
      friday_9am: false,
      friday_10am: false,
      friday_11am: false,
      friday_12pm: false,
      friday_1pm: false,
      friday_2pm: false,
      friday_3pm: false,
      friday_4pm: false,
      friday_5pm: false,
      friday_6pm: false,
      friday_7pm: false,
      friday_8pm: false
    }
  }

  public selectSidney () {
    this.unSelectAllHours()
    this.intraday_model = {
      sunday_9pm: false,
      sunday_10pm: false,
      sunday_11pm: true,
      monday_12am: true,
      monday_1am: true,
      monday_2am: true,
      monday_3am: true,
      monday_4am: true,
      monday_5am: true,
      monday_6am: true,
      monday_7am: true,
      monday_8am: false,
      monday_9am: false,
      monday_10am: false,
      monday_11am: false,
      monday_12pm: false,
      monday_1pm: false,
      monday_2pm: false,
      monday_3pm: false,
      monday_4pm: false,
      monday_5pm: false,
      monday_6pm: false,
      monday_7pm: false,
      monday_8pm: false,
      monday_9pm: false,
      monday_10pm: false,
      monday_11pm: true,
      tuesday_12am: true,
      tuesday_1am: true,
      tuesday_2am: true,
      tuesday_3am: true,
      tuesday_4am: true,
      tuesday_5am: true,
      tuesday_6am: true,
      tuesday_7am: true,
      tuesday_8am: false,
      tuesday_9am: false,
      tuesday_10am: false,
      tuesday_11am: false,
      tuesday_12pm: false,
      tuesday_1pm: false,
      tuesday_2pm: false,
      tuesday_3pm: false,
      tuesday_4pm: false,
      tuesday_5pm: false,
      tuesday_6pm: false,
      tuesday_7pm: false,
      tuesday_8pm: false,
      tuesday_9pm: false,
      tuesday_10pm: false,
      tuesday_11pm: true,
      wenesday_12am: true,
      wenesday_1am: true,
      wenesday_2am: true,
      wenesday_3am: true,
      wenesday_4am: true,
      wenesday_5am: true,
      wenesday_6am: true,
      wenesday_7am: true,
      wenesday_8am: false,
      wenesday_9am: false,
      wenesday_10am: false,
      wenesday_11am: false,
      wenesday_12pm: false,
      wenesday_1pm: false,
      wenesday_2pm: false,
      wenesday_3pm: false,
      wenesday_4pm: false,
      wenesday_5pm: false,
      wenesday_6pm: false,
      wenesday_7pm: false,
      wenesday_8pm: false,
      wenesday_9pm: false,
      wenesday_10pm: false,
      wenesday_11pm: true,
      thursday_12am: true,
      thursday_1am: true,
      thursday_2am: true,
      thursday_3am: true,
      thursday_4am: true,
      thursday_5am: true,
      thursday_6am: true,
      thursday_7am: true,
      thursday_8am: false,
      thursday_9am: false,
      thursday_10am: false,
      thursday_11am: false,
      thursday_12pm: false,
      thursday_1pm: false,
      thursday_2pm: false,
      thursday_3pm: false,
      thursday_4pm: false,
      thursday_5pm: false,
      thursday_6pm: false,
      thursday_7pm: false,
      thursday_8pm: false,
      thursday_9pm: false,
      thursday_10pm: false,
      thursday_11pm: true,
      friday_12am: true,
      friday_1am: true,
      friday_2am: true,
      friday_3am: true,
      friday_4am: true,
      friday_5am: true,
      friday_6am: true,
      friday_7am: true,
      friday_8am: false,
      friday_9am: false,
      friday_10am: false,
      friday_11am: false,
      friday_12pm: false,
      friday_1pm: false,
      friday_2pm: false,
      friday_3pm: false,
      friday_4pm: false,
      friday_5pm: false,
      friday_6pm: false,
      friday_7pm: false,
      friday_8pm: false
    }
  }

  public selectTokyo () {
    this.unSelectAllHours()
    this.intraday_model = {
      sunday_9pm: false,
      sunday_10pm: false,
      sunday_11pm: false,
      monday_12am: true,
      monday_1am: true,
      monday_2am: true,
      monday_3am: true,
      monday_4am: true,
      monday_5am: true,
      monday_6am: true,
      monday_7am: true,
      monday_8am: true,
      monday_9am: true,
      monday_10am: false,
      monday_11am: false,
      monday_12pm: false,
      monday_1pm: false,
      monday_2pm: false,
      monday_3pm: false,
      monday_4pm: false,
      monday_5pm: false,
      monday_6pm: false,
      monday_7pm: false,
      monday_8pm: false,
      monday_9pm: false,
      monday_10pm: false,
      monday_11pm: false,
      tuesday_12am: true,
      tuesday_1am: true,
      tuesday_2am: true,
      tuesday_3am: true,
      tuesday_4am: true,
      tuesday_5am: true,
      tuesday_6am: true,
      tuesday_7am: true,
      tuesday_8am: true,
      tuesday_9am: true,
      tuesday_10am: false,
      tuesday_11am: false,
      tuesday_12pm: false,
      tuesday_1pm: false,
      tuesday_2pm: false,
      tuesday_3pm: false,
      tuesday_4pm: false,
      tuesday_5pm: false,
      tuesday_6pm: false,
      tuesday_7pm: false,
      tuesday_8pm: false,
      tuesday_9pm: false,
      tuesday_10pm: false,
      tuesday_11pm: false,
      wenesday_12am: true,
      wenesday_1am: true,
      wenesday_2am: true,
      wenesday_3am: true,
      wenesday_4am: true,
      wenesday_5am: true,
      wenesday_6am: true,
      wenesday_7am: true,
      wenesday_8am: true,
      wenesday_9am: true,
      wenesday_10am: false,
      wenesday_11am: false,
      wenesday_12pm: false,
      wenesday_1pm: false,
      wenesday_2pm: false,
      wenesday_3pm: false,
      wenesday_4pm: false,
      wenesday_5pm: false,
      wenesday_6pm: false,
      wenesday_7pm: false,
      wenesday_8pm: false,
      wenesday_9pm: false,
      wenesday_10pm: false,
      wenesday_11pm: false,
      thursday_12am: true,
      thursday_1am: true,
      thursday_2am: true,
      thursday_3am: true,
      thursday_4am: true,
      thursday_5am: true,
      thursday_6am: true,
      thursday_7am: true,
      thursday_8am: true,
      thursday_9am: true,
      thursday_10am: false,
      thursday_11am: false,
      thursday_12pm: false,
      thursday_1pm: false,
      thursday_2pm: false,
      thursday_3pm: false,
      thursday_4pm: false,
      thursday_5pm: false,
      thursday_6pm: false,
      thursday_7pm: false,
      thursday_8pm: false,
      thursday_9pm: false,
      thursday_10pm: false,
      thursday_11pm: false,
      friday_12am: true,
      friday_1am: true,
      friday_2am: true,
      friday_3am: true,
      friday_4am: true,
      friday_5am: true,
      friday_6am: true,
      friday_7am: true,
      friday_8am: true,
      friday_9am: true,
      friday_10am: false,
      friday_11am: false,
      friday_12pm: false,
      friday_1pm: false,
      friday_2pm: false,
      friday_3pm: false,
      friday_4pm: false,
      friday_5pm: false,
      friday_6pm: false,
      friday_7pm: false,
      friday_8pm: false
    }
  }

  public selectLondon () {
    this.unSelectAllHours()
    this.intraday_model = {
      sunday_9pm: false,
      sunday_10pm: false,
      sunday_11pm: false,
      monday_12am: false,
      monday_1am: false,
      monday_2am: false,
      monday_3am: false,
      monday_4am: false,
      monday_5am: false,
      monday_6am: false,
      monday_7am: false,
      monday_8am: true,
      monday_9am: true,
      monday_10am: true,
      monday_11am: true,
      monday_12pm: true,
      monday_1pm: true,
      monday_2pm: true,
      monday_3pm: true,
      monday_4pm: true,
      monday_5pm: false,
      monday_6pm: false,
      monday_7pm: false,
      monday_8pm: false,
      monday_9pm: false,
      monday_10pm: false,
      monday_11pm: false,
      tuesday_12am: false,
      tuesday_1am: false,
      tuesday_2am: false,
      tuesday_3am: false,
      tuesday_4am: false,
      tuesday_5am: false,
      tuesday_6am: false,
      tuesday_7am: false,
      tuesday_8am: true,
      tuesday_9am: true,
      tuesday_10am: true,
      tuesday_11am: true,
      tuesday_12pm: true,
      tuesday_1pm: true,
      tuesday_2pm: true,
      tuesday_3pm: true,
      tuesday_4pm: true,
      tuesday_5pm: false,
      tuesday_6pm: false,
      tuesday_7pm: false,
      tuesday_8pm: false,
      tuesday_9pm: false,
      tuesday_10pm: false,
      tuesday_11pm: false,
      wenesday_12am: false,
      wenesday_1am: false,
      wenesday_2am: false,
      wenesday_3am: false,
      wenesday_4am: false,
      wenesday_5am: false,
      wenesday_6am: false,
      wenesday_7am: false,
      wenesday_8am: true,
      wenesday_9am: true,
      wenesday_10am: true,
      wenesday_11am: true,
      wenesday_12pm: true,
      wenesday_1pm: true,
      wenesday_2pm: true,
      wenesday_3pm: true,
      wenesday_4pm: true,
      wenesday_5pm: false,
      wenesday_6pm: false,
      wenesday_7pm: false,
      wenesday_8pm: false,
      wenesday_9pm: false,
      wenesday_10pm: false,
      wenesday_11pm: false,
      thursday_12am: false,
      thursday_1am: false,
      thursday_2am: false,
      thursday_3am: false,
      thursday_4am: false,
      thursday_5am: false,
      thursday_6am: false,
      thursday_7am: false,
      thursday_8am: true,
      thursday_9am: true,
      thursday_10am: true,
      thursday_11am: true,
      thursday_12pm: true,
      thursday_1pm: true,
      thursday_2pm: true,
      thursday_3pm: true,
      thursday_4pm: true,
      thursday_5pm: false,
      thursday_6pm: false,
      thursday_7pm: false,
      thursday_8pm: false,
      thursday_9pm: false,
      thursday_10pm: false,
      thursday_11pm: false,
      friday_12am: false,
      friday_1am: false,
      friday_2am: false,
      friday_3am: false,
      friday_4am: false,
      friday_5am: false,
      friday_6am: false,
      friday_7am: false,
      friday_8am: true,
      friday_9am: true,
      friday_10am: true,
      friday_11am: true,
      friday_12pm: true,
      friday_1pm: true,
      friday_2pm: true,
      friday_3pm: true,
      friday_4pm: true,
      friday_5pm: false,
      friday_6pm: false,
      friday_7pm: false,
      friday_8pm: false
    }
  }

  public selectNewYork () {
    this.unSelectAllHours()
    this.intraday_model = {
      sunday_9pm: false,
      sunday_10pm: false,
      sunday_11pm: false,
      monday_12am: false,
      monday_1am: false,
      monday_2am: false,
      monday_3am: false,
      monday_4am: false,
      monday_5am: false,
      monday_6am: false,
      monday_7am: false,
      monday_8am: false,
      monday_9am: false,
      monday_10am: false,
      monday_11am: false,
      monday_12pm: false,
      monday_1pm: true,
      monday_2pm: true,
      monday_3pm: true,
      monday_4pm: true,
      monday_5pm: true,
      monday_6pm: true,
      monday_7pm: true,
      monday_8pm: true,
      monday_9pm: false,
      monday_10pm: false,
      monday_11pm: false,
      tuesday_12am: false,
      tuesday_1am: false,
      tuesday_2am: false,
      tuesday_3am: false,
      tuesday_4am: false,
      tuesday_5am: false,
      tuesday_6am: false,
      tuesday_7am: false,
      tuesday_8am: false,
      tuesday_9am: false,
      tuesday_10am: false,
      tuesday_11am: false,
      tuesday_12pm: false,
      tuesday_1pm: true,
      tuesday_2pm: true,
      tuesday_3pm: true,
      tuesday_4pm: true,
      tuesday_5pm: true,
      tuesday_6pm: true,
      tuesday_7pm: true,
      tuesday_8pm: true,
      tuesday_9pm: false,
      tuesday_10pm: false,
      tuesday_11pm: false,
      wenesday_12am: false,
      wenesday_1am: false,
      wenesday_2am: false,
      wenesday_3am: false,
      wenesday_4am: false,
      wenesday_5am: false,
      wenesday_6am: false,
      wenesday_7am: false,
      wenesday_8am: false,
      wenesday_9am: false,
      wenesday_10am: false,
      wenesday_11am: false,
      wenesday_12pm: false,
      wenesday_1pm: true,
      wenesday_2pm: true,
      wenesday_3pm: true,
      wenesday_4pm: true,
      wenesday_5pm: true,
      wenesday_6pm: true,
      wenesday_7pm: true,
      wenesday_8pm: true,
      wenesday_9pm: false,
      wenesday_10pm: false,
      wenesday_11pm: false,
      thursday_12am: false,
      thursday_1am: false,
      thursday_2am: false,
      thursday_3am: false,
      thursday_4am: false,
      thursday_5am: false,
      thursday_6am: false,
      thursday_7am: false,
      thursday_8am: false,
      thursday_9am: false,
      thursday_10am: false,
      thursday_11am: false,
      thursday_12pm: false,
      thursday_1pm: true,
      thursday_2pm: true,
      thursday_3pm: true,
      thursday_4pm: true,
      thursday_5pm: true,
      thursday_6pm: true,
      thursday_7pm: true,
      thursday_8pm: true,
      thursday_9pm: false,
      thursday_10pm: false,
      thursday_11pm: false,
      friday_12am: false,
      friday_1am: false,
      friday_2am: false,
      friday_3am: false,
      friday_4am: false,
      friday_5am: false,
      friday_6am: false,
      friday_7am: false,
      friday_8am: false,
      friday_9am: false,
      friday_10am: false,
      friday_11am: false,
      friday_12pm: false,
      friday_1pm: true,
      friday_2pm: true,
      friday_3pm: true,
      friday_4pm: true,
      friday_5pm: true,
      friday_6pm: true,
      friday_7pm: true,
      friday_8pm: true
    }
  }

  public selectAllHours () {
    this.intraday_model = {
      sunday_9pm: false,
      sunday_10pm: false,
      sunday_11pm: true,
      monday_12am: true,
      monday_1am: true,
      monday_2am: true,
      monday_3am: true,
      monday_4am: true,
      monday_5am: true,
      monday_6am: true,
      monday_7am: true,
      monday_8am: true,
      monday_9am: true,
      monday_10am: true,
      monday_11am: true,
      monday_12pm: true,
      monday_1pm: true,
      monday_2pm: true,
      monday_3pm: true,
      monday_4pm: true,
      monday_5pm: true,
      monday_6pm: true,
      monday_7pm: true,
      monday_8pm: true,
      monday_9pm: false,
      monday_10pm: false,
      monday_11pm: true,
      tuesday_12am: true,
      tuesday_1am: true,
      tuesday_2am: true,
      tuesday_3am: true,
      tuesday_4am: true,
      tuesday_5am: true,
      tuesday_6am: true,
      tuesday_7am: true,
      tuesday_8am: true,
      tuesday_9am: true,
      tuesday_10am: true,
      tuesday_11am: true,
      tuesday_12pm: true,
      tuesday_1pm: true,
      tuesday_2pm: true,
      tuesday_3pm: true,
      tuesday_4pm: true,
      tuesday_5pm: true,
      tuesday_6pm: true,
      tuesday_7pm: true,
      tuesday_8pm: true,
      tuesday_9pm: false,
      tuesday_10pm: false,
      tuesday_11pm: true,
      wenesday_12am: true,
      wenesday_1am: true,
      wenesday_2am: true,
      wenesday_3am: true,
      wenesday_4am: true,
      wenesday_5am: true,
      wenesday_6am: true,
      wenesday_7am: true,
      wenesday_8am: true,
      wenesday_9am: true,
      wenesday_10am: true,
      wenesday_11am: true,
      wenesday_12pm: true,
      wenesday_1pm: true,
      wenesday_2pm: true,
      wenesday_3pm: true,
      wenesday_4pm: true,
      wenesday_5pm: true,
      wenesday_6pm: true,
      wenesday_7pm: true,
      wenesday_8pm: true,
      wenesday_9pm: false,
      wenesday_10pm: false,
      wenesday_11pm: true,
      thursday_12am: true,
      thursday_1am: true,
      thursday_2am: true,
      thursday_3am: true,
      thursday_4am: true,
      thursday_5am: true,
      thursday_6am: true,
      thursday_7am: true,
      thursday_8am: true,
      thursday_9am: true,
      thursday_10am: true,
      thursday_11am: true,
      thursday_12pm: true,
      thursday_1pm: true,
      thursday_2pm: true,
      thursday_3pm: true,
      thursday_4pm: true,
      thursday_5pm: true,
      thursday_6pm: true,
      thursday_7pm: true,
      thursday_8pm: true,
      thursday_9pm: false,
      thursday_10pm: false,
      thursday_11pm: true,
      friday_12am: true,
      friday_1am: true,
      friday_2am: true,
      friday_3am: true,
      friday_4am: true,
      friday_5am: true,
      friday_6am: true,
      friday_7am: true,
      friday_8am: true,
      friday_9am: true,
      friday_10am: true,
      friday_11am: true,
      friday_12pm: true,
      friday_1pm: true,
      friday_2pm: true,
      friday_3pm: true,
      friday_4pm: true,
      friday_5pm: true,
      friday_6pm: true,
      friday_7pm: true,
      friday_8pm: true
    }
  }

  private async loadLynch () {
    this.resultSelected = []
    this.show_selector = true
    this.total_winrate = 0
    this.total_amount = 0
    this.total_bets = 0
    this.total_max_dropdown = 0
    this.resultLoading = true
    this.lynchRunning = true
    try {
      this.resultHeaders = []
      this.resultList = []
      this.resultListFiltered = []
      const data = await axios.post(`${this.$store.state.URL_API}/finance/lynch2`, {}, { headers: authHeader() })
      const df = JSON.parse(data.data.data.data)
      const keys = Object.keys(df[0])
      keys.map((key) => {
        this.resultHeaders.push({ text: key, value: key })
      })
      this.resultList = this.resultList.concat(df)
      this.resultListFiltered = this.resultList
      this.filterData()
      this.calculate_totals()
    } catch (e) {

    }
    this.resultLoading = false
    this.lynchRunning = false
  }

  public exportExcel () {
    console.log('Excel')
  }

  public handleCheckAllSymbols () {
    this.symbolsSelected = []
    this.symbolsSelected = this.symbolsList.map(symbol => symbol.id)
  }

  public $refs!: {
    menuHourSelector: any
    selectDates: any;
    dialogWait: any;
    dialogMessage: any;
    dialogYesNo: any;
    apexChart: any;
  }

  public makeFullScreen (id: string) {
    this.fullScreenStatus = true
    const divObj = document.getElementById(id)
    if (divObj) {
      if (divObj.requestFullscreen) {
        divObj.requestFullscreen()
      }
    }
  }

  public cancelFullScreen () {
    this.fullScreenStatus = false
    if (document.exitFullscreen) {
      document.exitFullscreen()
    }
  }
}
