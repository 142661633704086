import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'
import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)
Vue.use(Vuetify)
Vue.config.productionTip = false

new Vue({
  el: '#app',
  vuetify: new Vuetify({
    theme: {
      options: {
        customProperties: true
      },
      themes: {
        light: {
          // primary: colors.lightGreen.base,
          primary: '#EC407A',
          secondary: colors.grey.base,
          accent: colors.shades.black,
          error: colors.red.accent3
        },
        dark: {
          // primary: colors.lightGreen.base,
          primary: '#EC407A',
          background: '#363636'
        }
      },
      dark: localStorage.dark === 'true'
    }
  }),
  router,
  store,
  beforeCreate () {
    this.$store.dispatch('initialState') // dispatch loading
  },
  render: h => h(App)
}).$mount('#app')
