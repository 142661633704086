
/* eslint-disable */
import { Component, Vue, Watch } from 'vue-property-decorator'
import authHeader from '../services/auth-header'
import DatesSelector from '../components/DatesSelector.vue'
import axios from 'axios'
import DialogWait from '../components/DialogWait.vue'
import DialogYesNo from '../components/DialogYesNo.vue'
import ApexChart from 'vue-apexcharts'
import moment from 'moment'

@Component({
  components: {
    DatesSelector,
    DialogWait,
    DialogYesNo,
    ApexChart
  },
})
export default class Home extends Vue {
  public loadingSpreadHistory = false
  public betChartSeries: any[] = []
  public betChartStandarizedSeries: any[] = []
  public betChartNormalizedSeries: any[] = []


  public betChartSeriesOptions : any = {
    chart: {
      id: 'betChartSeries',
      animations: {
        enabled: true
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    stroke: {
      curve: "smooth",
      width: 3
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'yyyy-MM-dd HH:mm',
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        offsetX: 14,
        offsetY: -5
      },
      tooltip: {
        enabled: true
      }
    },
    tooltip: {
      x: {
        format: 'yyyy-MM-dd HH:mm',
      }
    },
    legend: {
      show: true,
      position: 'top'
    }
  }

  public betChartStandarizedSeriesOptions : any = {
    chart: {
      id: 'betChartStandarized',
      animations: {
        enabled: true
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    stroke: {
      curve: "smooth",
      width: 3
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'yyyy-MM-dd HH:mm',
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        offsetX: 14,
        offsetY: -5
      },
      tooltip: {
        enabled: true
      }
    },
    tooltip: {
      x: {
        format: 'yyyy-MM-dd HH:mm',
      }
    },
    legend: {
      show: true,
      position: 'top'
    }
  }

  public betChartNormalizedSeriesOptions : any = {
    chart: {
      id: 'apexBalancebetChartNormalizedSeriesChart',
      animations: {
        enabled: true
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    stroke: {
      curve: "smooth",
      width: 3
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'yyyy-MM-dd HH:mm',
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        offsetX: 14,
        offsetY: -5
      },
      tooltip: {
        enabled: true
      }
    },
    tooltip: {
      x: {
        format: 'yyyy-MM-dd HH:mm',
      }
    },
    legend: {
      show: true,
      position: 'top'
    }
  }

  public showDetails = true
  public dialogShowBetChart = false
  public dialogInfo = false
  public panel: any[] = []
  public search: any[] = [0]
  public summary: any[] = [0]
  public balanceExpansion: any[] = [0]
  public spreadExpansion: any[] = [0]
  public spreadHistoricalExpansion: any[] = [0]
  public betHistoricalExpansion: any[] = [0]
  public lastClosedBetsExpansion: any[] = [0]
  public betInfo: any = {}
  public lastClosedBetSeach = ''

  public balanceChartVisible = false

  public series: any[] = []
  public balanceChartSeries: any[] = []
  public betHistoricalChartSeries: any[] = []
  $router: any

  public closeChartBalance() {
    this.balanceChartVisible = false
  }

  public refreshChart(chart: any) {
    return new Promise<void>(async (resolve) => {
      if (chart) await chart.refresh()
      resolve()
    })
  }

  public balanceChartOptions : any = {
    chart: {
      id: 'apexBalanceChart',
      animations: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0
    },
    stroke: {
      curve: "smooth",
      width: 3
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'yyyy-MM-dd HH:mm',
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      labels: {
        offsetX: 14,
        offsetY: -5
      },
      tooltip: {
        enabled: true
      }
    },
    tooltip: {
      x: {
        format: 'yyyy-MM-dd HH:mm',
      }
    },
    legend: {
      show: true,
      position: 'top'
    }
  }

 public betHistoricalChartOptions = {
    chart: {
      id: 'apexBetHistoricalChart',
      animations: {
        enabled: false
      }
    },
    stroke: {
      curve: "smooth",
      width: 3
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      borderColor: "#555",
      clipMarkers: false,
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    markers: {
      size: 2,
      colors: ["#000524"],
      strokeColor: "#00BAEC",
      strokeWidth: 2
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'yyyy-MM-dd HH:mm',
      }
    },
    tooltip: {
      x: {
        format: 'yyyy-MM-dd HH:mm',
      }
    },
    legend: {
      show: true,
      position: 'top'
    }
  }

  public options = {
    chart: {
      id: 'backtesting_chart',
      animations: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: '#3C90EB',
          downward: '#3C90EB'
        }
      }
    },
    xaxis: {
      type: 'category',
      labels: {
        formatter: function (val: any) {
          return new Date(val * 1000).toLocaleDateString('es-es', { hour12: false, hour: '2-digit' })
        }
      }
    }, legend: {
      show: true,
      position: 'top'
    }
  }

  @Watch('betHistoricalVersionSelected')
  public async onBetHistoricalVersionSelected() {
    this.handleBetHistoricalChart()
  }

  public showBalanceChart(id: any) {
    this.balanceChartVisible = true
    this.getBalanceChart(id)
  }

  public async handleLastClosedBets () {
    this.lastClosedBets.loading = true
    const { data } = await axios.get(
      `${this.$store.state.URL_API}/finance/v2/bets`,
      {
        params: {
          versions: [-1],
          platforms: [-1],
          showClosed: 2,
          showBinary: true,
          statusSelected: 0,
          dateBegin: moment().add(-30, 'days').format('Y-MM-DD'),
          dateEnd: moment().format('Y-MM-DD'),
          versionStatus: 1
        },
        headers: authHeader()
      }
    )
    this.lastClosedBets.rows = data.rows
    this.lastClosedBets.loading = false
  }

  public async handleBetHistoricalChart () {
    this.betHistoricalChartSeries = []
    let rows = await axios.get(`${this.$store.state.URL_API}/finance/v2/version/${this.betHistoricalVersionSelected}/bet/history/cumsum`, { headers: authHeader() })
    let rows2 = rows.data.rows
    const amount_rows = rows2.map((a: any) => [a.moment2, a.amount])
    const cumsum_rows = rows2.map((a: any) => [a.moment2, a.cumsum])
    // const moments = rows.map((a) => a.moment)
    // this.betHistoricalChartOptions.xaxis.categories = moments
    this.betHistoricalChartSeries = [
      {
        name: 'Bet',
        data: amount_rows
      },
      {
        name: 'CumSum',
        data: cumsum_rows
      }
    ]
  }

  public async getBalanceChart(id: number) {
    const{ data } = await axios.get(`${this.$store.state.URL_API}/finance/v2/balance/${id}`, { headers: authHeader() })
    this.balanceChartSeries = [{
      name: 'Balance', 
      type: 'candlestick',
      data: data.balance
    }, {
      name: 'Equity',
      type: 'line',
      data: data.equity
    }]
  }

  public spreads = {
    loading: false,
    summary: '',
    rows: [],
    headers: [
      { text: 'ID', value: 'id' },
      { text: 'Name', value: 'name' },
      { text: 'Current', value: 'spread_percent_dwx' },
      { text: 'Min', value: 'spread_dwx_min' },
      { text: 'Max', value: 'spread_dwx_max' },
      { text: 'Mean', value: 'spread_dwx_avg' }
    ]
  }

  public lastClosedBets = {
    loading: false,
    summary: '',
    rows: [],
    headers: [
      { text: 'Actions', value: 'action' },
      { text: 'Open Date', value: 'moment' },
      { text: 'Close Date', value: 'moment_close' },
      { text: 'Ticks', value: 'close_bet_duration_in_ticks' },
      { text: 'Name', value: 'xtb_symbol' },
      { text: 'Versión - Group', value: 'name_groping' },
      { text: 'Prediction', value: 'prediction' },
      { text: 'Amount', value: 'amount' },
      { text: 'Motive', value: 'request_close_motive' }
    ]
  }

  public balance = {
    loading: false,
    rows: [],
    headers: [
      { text: 'Account', value: 'name' },
      { text: 'Equity', value: 'balance.equity' },
      { text: 'Balance', value: 'balance.balance' },
      { text: 'Margin', value: 'balance.margin' },
      { text: 'Margin Free', value: 'balance.margin_free' },
      { text: 'Currency', value: 'balance.currency' }
    ]
  }

  public getColor(item: any) {
    if (item.amount >= 0) {
      return '#4caf50'
    } else {
      return 'red'
    }
  }

  public getColorValue(amount: any) {
    if (amount >= 0) {
      return '#4caf50'
    } else {
      return 'red'
    }
  }

  public getColor2(value: any) {
    if (value >= 60) {
      return '#4caf50'
    } else {
      return 'red'
    }
  }

  public statusSelected = 1
  public statusList = [
    {
      id: 0,
      name: 'All'
    },
    {
      id: 1,
      name: 'Open'
    },
    {
      id: 2,
      name: 'Close'
    }
  ]

  public loading = false

  public betsRows = []
  public total_bets = 0
  public total_amount = 0
  public opened_bets = 0
  public opened_amount = 0
  public closed_bets = 0
  public closed_amount = 0

  public platforms = this.$store.state.platforms
  public platformsSelected = []

  public brokers = this.$store.state.brokers
  public brokersSelected = []

  public versions = []
  public versionsList = this.$store.state.versions

  public versionStatus = [
    {
      id: 0,
      name: 'All'
    },
    {
      id: 1,
      name: 'Active'
    },
    {
      id: 2,
      name: 'Non active'
    }
  ]

  public versionStatusSelected = 1

  public traders = []

  public betHistoricalVersionSelected = 0

  private async mounted () {
    await this.$store.dispatch('initialState', this)
    this.platforms = this.$store.state.platforms
    this.brokers = this.$store.state.brokers
    this.versionsList = this.$store.state.versions
    this.loadPage()
  }

  private async loadPage () {
    clearInterval(this.$store.state.betTimer1)
    await this.retreiveData()
    
    this.handleLastClosedBets()

    this.$store.state.betTimer1 = setInterval(async () => {
      this.retreiveData()
      this.handleLastClosedBets()
    }, 30000)
  }

  public async retreiveData() {
    axios.get(`${this.$store.state.URL_API}/finance/traders`, { headers: authHeader() }).then((data) => {
      this.balance.rows = data.data.rows
    })

    const { dateBegin, dateEnd } = this.$refs.selectDates as any
    this.loading = true
    try {
      const res = await axios.get(`${this.$store.state.URL_API}/finance/v2/bets/status`, {
        params: {
          versions: this.versions.length > 0 ? this.versions : [-1],
          platforms: this.platformsSelected.length > 0 ? this.platformsSelected : [-1],
          brokers: this.brokersSelected.length > 0 ? this.brokersSelected : [-1],
          versionStatus: this.versionStatusSelected,
          status: this.statusSelected,
          dateBegin,
          dateEnd,
          showDetails: (this.showDetails ? 1 : 0)
        },
        headers: authHeader()
      })

      this.betsRows = res.data.rows
      this.total_bets = res.data.summary.total_bets
      this.total_amount = res.data.summary.total_amount
      this.opened_bets = res.data.summary.opened_bets
      this.opened_amount = res.data.summary.opened_amount
      this.closed_bets = res.data.summary.closed_bets
      this.closed_amount = res.data.summary.closed_amount
    } catch (e) {
      console.error(e)
    }
    this.loading = false
  }

  public async showChart(symbol: string) {
    window.open(`/chart?symbol=${symbol}`)
  }

  public async showBetChart(item: object) {
    this.$refs.dialogWait.open()
    console.log(item)
    const{ data } = await axios.get(`${this.$store.state.URL_API}/finance/v2/bet/${item.id}/ohlc`, { headers: authHeader() })

    this.betChartSeries = [{
      name: 'Bet', 
      type: 'candlestick',
      data: data.rates
    }]

    this.betChartStandarizedSeries = [{
      name: 'Standarized', 
      type: 'candlestick',
      data: data.standarized
    }]

    this.betChartNormalizedSeries = [{
      name: 'Normalized', 
      type: 'candlestick',
      data: data.normalized
    }]

    this.betChartSeriesOptions.annotations = {
      xaxis: [
        {
          // in a datetime series, the x value should be a timestamp, just like it is generated below
          x: new Date(item.moment).getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0",
        },
        {
          // in a datetime series, the x value should be a timestamp, just like it is generated below
          x: new Date(item.moment_close).getTime(),
          strokeDashArray: 0,
          borderColor: "blue",
        },
      ]
    }
    
    this.betChartStandarizedSeriesOptions.annotations = {
      xaxis: [
        {
          // in a datetime series, the x value should be a timestamp, just like it is generated below
          x: new Date(item.moment).getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0",
        },
        {
          // in a datetime series, the x value should be a timestamp, just like it is generated below
          x: new Date(item.moment_close).getTime(),
          strokeDashArray: 0,
          borderColor: "blue",
        },
      ]
    }

    this.betChartNormalizedSeriesOptions.annotations = {
      xaxis: [
        {
          // in a datetime series, the x value should be a timestamp, just like it is generated below
          x: new Date(item.moment).getTime(),
          strokeDashArray: 0,
          borderColor: "#775DD0",
        },
        {
          // in a datetime series, the x value should be a timestamp, just like it is generated below
          x: new Date(item.moment_close).getTime(),
          strokeDashArray: 0,
          borderColor: "blue",
        },
      ]
    }
    
    this.$refs.dialogWait.close()
    this.dialogShowBetChart = true
  }

  public showInfo(item: any) {
    this.betInfo = item
    this.dialogInfo = true
  }

  public goToLynch (version: Number, row: any) {
    let symbol = ''
    if (row) symbol = row.symbol_id
    let routeData = this.$router.resolve({name: 'Lynch', query: {version, symbol}});
    window.open(routeData.href, '_blank');
  }

  public $refs!: {
    selectDates: any
    dialogWait: any
    dialogYesNo: any
    apexChart: any
    apexChartBalance: any
  }

  $store: any
}
