
/* eslint-disable */
import { Component, Vue, Ref } from 'vue-property-decorator'
import axios from 'axios'
import authHeader from '../services/auth-header'
import DatesSelector from '../components/DatesSelector.vue'
@Component({
  components: {
    DatesSelector: DatesSelector
  }
})
export default class Statistics extends Vue {
  public searchExpansion = [0]
  public loading = false
  public search_strategy: string = ''
  public timer: any
  public timer2: any
  public $refs!: {
    selectDates: any
  }

  public bestOfBetsVersionHeader = [
    { text: 'Version', value: 'name_groping' },
    { text: 'Samples', value: 'total' },
    { text: 'Hits %', value: 'aciertos' }
  ]

  public bestOfBetsVersionStepHeader = [
    { text: 'Version', value: 'name_groping' },
    { text: 'Step', value: 'step' },
    { text: 'Samples', value: 'total' },
    { text: 'Hits %', value: 'aciertos' }
  ]

  public bestOfBetsVersionRows = []

  public bestOfBetsSymbolHeader = [
    { text: 'Id', value: 'symbol_id' },
    { text: 'Name', value: 'name' },
    { text: 'Version', value: 'name_groping' },
    { text: 'Samples', value: 'total' },
    { text: 'Hits %', value: 'aciertos' }
  ]

  public bestOfBetsSymbolRows = []

  public platforms = this.$store.state.platforms
  public platformsSelected = []
  public search = ''
  public versions = []
  public versionsList = this.$store.state.versions

  public headers = [
    { text: 'Symbol Id', value: 'symbol_id' },
    { text: 'Name', value: 'name' },
    { text: 'Version', value: 'coef_version' },
    { text: 'Samples', value: 'muestras' },
    { text: 'Hits %', value: 'acierto' }
  ]

  private async mounted() {
    await this.$store.dispatch('initialState', this)
    this.versionsList = this.$store.state.versions
    this.platforms = this.$store.state.platforms
    this.retreiveData()
    clearInterval(this.$store.state.betResultsTimer1)
  }

  private async retreiveData() {
    this.loading = true
    const { dateBegin, dateEnd } = this.$refs.selectDates as any
    try {
      const [res] = await Promise.all([
        axios.get(
          `${this.$store.state.URL_API}/finance/v2/statistics`,
          {
            params: {
              dateBegin,
              dateEnd,
              versions: this.versions.length > 0 ? this.versions : [-1],
              platforms: this.platformsSelected.length > 0 ? this.platformsSelected : [-1],
            },
            headers: authHeader()
          }
        )
      ])
      this.bestOfBetsVersionRows = res.data.all
      this.bestOfBetsSymbolRows = res.data.symbols
    } catch (e) {
      console.error(e)
    }
    this.loading = false
  }
}
