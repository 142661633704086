
/* eslint-disable */
import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import axios from 'axios'
import authHeader from '../services/auth-header'
@Component({
  components: {
  }
})
export default class Notifications extends Vue {
  public notificationsLoading = false
  public notificationsHeaders = [
    { text: 'Date', value: 'moment' },
    { text: 'Message', value: 'message' },
    { text: 'Actions', value: 'actions' },
  ]

  $store: any

  public notificationsList = []

  private async mounted() {
    this.retreiveData()
  }

  public async read (item: any) {
    try {
      await axios.post(`${this.$store.state.URL_API}/finance/notification/${item.id}/read`, {}, { headers: authHeader() })
    } catch (e) {
      this.$refs.dialogMessage.open('Error', `An error has occurred during this action, we apologize for it.`, { color: 'red' })
    }
    this.retreiveData()
  }

  public refresh() {
    clearInterval(this.$store.state.betTimer1)

    this.retreiveData()

    this.$store.state.betTimer1 = setInterval(async () => {
      this.retreiveData()
    }, 30000)
  }

  public async readAll () {
    try {
      await axios.post(`${this.$store.state.URL_API}/finance/notification/read/all`, {}, { headers: authHeader() })
    } catch (e) {
      this.$refs.dialogMessage.open('Error', `An error has occurred during this action, we apologize for it.`, { color: 'red' })
    }
    this.retreiveData()
  }

  private async retreiveData() {
    this.notificationsLoading = true
    try {
      const res = await axios.get(
        `${this.$store.state.URL_API}/finance/v2/notifications`,
        {
          params: {
          },
          headers: authHeader()
        }
      )

      this.notificationsList = res.data.rows
    } catch (e) {
      console.error(e)
    }

    this.notificationsLoading = false
  }

  public $refs!: {
    dialogMessage: any;
  }

}
