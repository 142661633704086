
import { Component, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import authHeader from '../services/auth-header'

@Component({
  components: {
  }
})
export default class Symbols extends Vue {
  public showActive = true
  public showPredict = true
  public showBetBot = true
  public searchExpansion = [0]

  @Watch('showActive')
  onSshowActive (value: string, oldValue: string) {
    this.retreiveData()
  }

  @Watch('showPredict')
  onShowPredict (value: string, oldValue: string) {
    this.retreiveData()
  }

  @Watch('showBetBot')
  onShowBetBot (value: string, oldValue: string) {
    this.retreiveData()
  }

  public symbolsLoading = false

  public symbolsSearch = ''
  public symbolsHeaders = [
    { text: 'Symbol Id', value: 'id' },
    { text: 'Name', value: 'name' },
    { text: 'Description', value: 'description' }
  ]

  public symbolsRows = []

  private async mounted () {
    this.retreiveData()
  }

  private async retreiveData () {
    this.symbolsLoading = true

    const [res2] = await Promise.all([
      axios.get(`${this.$store.state.URL_API}/finance/symbols`, {
        headers: authHeader(),
        params: {
          showActive: this.showActive,
          showPredict: this.showPredict,
          showBetBot: this.showBetBot
        }
      })
    ])

    this.symbolsRows = res2.data.rows

    this.symbolsLoading = false
  }
}
