
/* eslint-disable */
import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import axios from 'axios'
import authHeader from '../services/auth-header'
import DatesSelector from '../components/DatesSelector.vue'
import DialogWait from '../components/DialogWait.vue'
@Component({
  components: {
    DatesSelector: DatesSelector,
    DialogWait: DialogWait
  }
})
export default class BetsBySpread extends Vue {
  public searchExpansion = [0]

  public predictionSearch = ''
  public versions = []
  public versionsList = this.$store.state.versions

  public showClosed = false
  public bet_status = -1
  public bet_status_list = [
    {
      id: -1,
      name: 'All'
    },
    {
      id: 0,
      name: 'Open'
    },
    {
      id: 1,
      name: 'Closed'
    },
    {
      id: 2,
      name: 'Pending'
    },
    {
      id: 3,
      name: 'Cancelled by Spread'
    },
    {
      id: 4,
      name: 'Error'
    }
  ]

  public betsLoading = false

  public betsHeaders = [
    { text: 'Symbol', value: 'name' },
    { text: 'Version', value: 'version' },
    { text: 'Group', value: 'grouping' },
    { text: 'Status', value: 'status' },
    { text: 'Moment', value: 'moment' },
    { text: 'Amount', value: 'amount' },
    { text: 'Prediction', value: 'prediction' },
    { text: 'Current Spread', value: 'spread_bet' },
    { text: '% Spread', value: 'spread_percent' },
    { text: 'Spread Avg', value: 'spread_avg' },
    { text: 'Spread Limit', value: 'spread_limit' },
    { text: 'Ask', value: 'ask' },
    { text: 'Bid', value: 'bid' },
    { text: 'Error', value: 'error' }
  ]

  public betsRows = []

  public getColor(item: any) {
    if (item.amount >= 0) {
      return 'green'
    } else {
        return 'red'
    }
  }

  private async mounted() {
    await this.$store.dispatch('initialState', this)
    this.versionsList = this.$store.state.versions
    this.retreiveData()
  }

  public refresh() {
    this.retreiveData()
  }

  private async retreiveData() {
    this.betsRows = []
    this.betsLoading = true
    const { dateBegin, dateEnd } = this.$refs.selectDates as any
    try {
      const res = await axios.get(
        `${this.$store.state.URL_API}/finance/v2/bets/bySpread`, { headers: authHeader(), params: {
            versions: this.versions.length > 0 ? this.versions : [-1],
            status: this.bet_status,
            dateBegin,
            dateEnd
        } 
      })

      this.betsRows = res.data.rows
    } catch (e) {
      console.error(e)
    }

    this.betsLoading = false
  }

  public $refs!: {
    selectDates: any
    dialogWait: any
  }

  $store: any

}
